import { $themeBreakpoints } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    currentPageTitle: null,
    currentRouteName: '',
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    currentPageTitle(state) {
      return state.currentPageTitle
    },
    currentRouteName(state) {
      return state.currentRouteName
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_CURRENT_PAGE_TITLE(state, val) {
      state.currentPageTitle = val
    },
    SET_CURRENT_ROUTE_NAME(state, val) {
      state.currentRouteName = val
    },
  },
  actions: {},
}
