import Vue from 'vue'
import axios from 'axios'
import bus from '@/bus'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  namespaced: true,
  state: {
    atmWizardTabs: {
      tableRowSelection: {
        label: 'Table Row Selection',
        active: true,
      },
      results: {
        label: 'Results',
        active: false,
      },
      test: {
        label: 'Test',
        active: false,
      },
    },
    atmAccordionOptions: [
      { key: 'green', label: 'Fully Match', visible: false },
      { key: 'pink', label: 'Very Close Match', visible: false },
      { key: 'yellow', label: 'Close Match', visible: false },
      { key: 'blank', label: 'Not Match', visible: false },
    ],
    atmConfig: null,
    chunkLineRecords: [],
    userSelectedPatterns: [],
    extendedUserSelectedPatterns: [],
    userSelectedOb: [],
    atmPatterns: [],
    selectedAtmPatterns: [],
    atmPatternRecords: [],
    atmPatternTableRows: {},
  },
  mutations: {
    SET_ATM_WIZARD_TABS(state, value) {
      state.atmWizardTabs = value
    },
    SET_ATM_ACCORDION_OPTIONS(state, value) {
      state.atmAccordionOptions = value
    },
    SET_ATM_CONFIG(state, value) {
      state.atmConfig = value
    },
    SET_CHUNK_LINE_RECORDS(state, value) {
      state.chunkLineRecords = value
    },
    SET_USER_SELECTED_PATTERNS(state, value) {
      state.userSelectedPatterns = value
    },
    SET_EXTENDED_USER_SELECTED_PATTERNS(state, value) {
      state.extendedUserSelectedPatterns = value
    },
    SET_USER_SELECTED_OB(state, value) {
      state.userSelectedOb = value
    },
    SET_ATM_PATTERNS(state, value) {
      state.atmPatterns = value
    },
    SET_SELECTED_ATM_PATTERNS(state, value) {
      state.selectedAtmPatterns = value
    },
    SET_ATM_PATTERN_RECORDS(state, value) {
      state.atmPatternRecords = value
    },
    SET_ATM_PATTERN_TABLE_ROWS(state, value) {
      state.atmPatternTableRows = value
    },
  },
  actions: {
    async fetchAtmChunkData({ rootGetters, commit }) {
      try {
        const res = await axios.post('/pipeline/atm_chunk_data/', {
          batch_id: rootGetters['batch/batch'].id,
          definition_version: rootGetters['dataView/selectedDefinitionVersion'],
        })

        commit('SET_CHUNK_LINE_RECORDS', res?.data?.data || [])
      } catch (error) {
        const message = error?.response?.data?.detail || 'Error fetching atm chunk data'
        throw new Error(message)
      }
    },
    findAtmPatterns({ rootGetters, commit, state }) {
      commit('dataView/SET_LOADING', true, { root: true })

      commit('SET_ATM_PATTERNS', [])
      commit('SET_ATM_PATTERN_RECORDS', [])
      commit('SET_SELECTED_ATM_PATTERNS', [])

      const selectedDefinition = rootGetters['dataView/selectedDefinition']
      const selectedTableId = rootGetters['dataView/selectedTableId']
      const multipleLineRecord = rootGetters['dataView/modelMultipleLineRecord']
      const { table_unique_id } = rootGetters['dataView/table'][selectedTableId]
      const selectedBatchId = rootGetters['batch/batch'].id
      const extendedUserSelectedPatterns = state.extendedUserSelectedPatterns.filter(e => e.length)

      const payload = {
        definition_id: selectedDefinition.definition_id,
        definition_type: selectedDefinition.type,
        name_matching_text: selectedDefinition.name_matching_text,
        batch_id: selectedBatchId,
        table_unique_id,
        record_line: state.atmConfig.record_line.value,
        digit_threshold: state.atmConfig.digit_threshold.value,
        text_threshold: state.atmConfig.text_threshold.value,
        user_selected_patterns: state.userSelectedPatterns.map(e => e.pattern),
        extended_user_selected_patterns: extendedUserSelectedPatterns.map(e => e.map(i => i.pattern)),
        multiple_line_record: multipleLineRecord,
        user_selected_ob: state.userSelectedOb,
        definition_version: rootGetters['dataView/selectedDefinitionVersion'],
      }

      axios.post('/pipeline/process_atm_data/', payload).catch(() => {
        commit('dataView/SET_LOADING', false, { root: true })
      })
    },
    loadAtmPatterns({ state, commit }, data) {
      if (!data) {
        commit('dataView/SET_LOADING', false, { root: true })

        return
      }

      if (data.atm_patterns && data.atm_pattern_records && state.atmWizardTabs.results.active) {
        commit('SET_ATM_PATTERNS', data.atm_patterns)
        commit('SET_ATM_PATTERN_RECORDS', data.atm_pattern_records)

        commit('dataView/SET_LOADING', false, { root: true })
      }
    },
    async runTest({ rootGetters, commit }) {
      commit('dataView/SET_LOADING', true, { root: true })

      const batch = rootGetters['batch/batch']
      const selectedDefinitionVersion = rootGetters['dataView/selectedDefinitionVersion']

      const params = {
        batch_id: batch.id,
        skip_post_processor: false,
        skip_key_processing: true,
        skip_table_processing: false,
        definition_version: selectedDefinitionVersion,
      }

      const currentRouteName = rootGetters['app/currentRouteName']

      if (currentRouteName === 'template-batch') {
        params.template = batch.definitionId
      }

      await axios.post('/pipeline/process_batch/', null, {
        params,
      }).then(res => {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.detail,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(error => {
        const message = error?.response?.data?.detail || 'Something went wrong'
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

      // commit('dataView/SET_LOADING', false, { root: true })
    },
    async refreshUserSelectedPatterns({ rootGetters, dispatch, commit }) {
      const userSelectedPatterns = rootGetters['dataView/modelUserSelectedPatterns']

      userSelectedPatterns.forEach(async e => {
        const pos = e.pos.split(',')

        pos[8] = 'blank'

        await dispatch('updateChunkLineRecords', pos.join(','))
      })

      commit('SET_USER_SELECTED_PATTERNS', userSelectedPatterns)
    },
    async refreshExtendUserSelectedPatterns({ rootGetters, dispatch, commit }) {
      const extendedUserSelectedPatterns = rootGetters['dataView/modelExtendedUserSelectedPatterns']

      extendedUserSelectedPatterns.forEach(item => {
        item.forEach(async e => {
          const pos = e.pos.split(',')

          pos[8] = 'blank'

          await dispatch('updateChunkLineRecords', pos.join(','))
        })
      })

      commit('SET_EXTENDED_USER_SELECTED_PATTERNS', extendedUserSelectedPatterns)
    },
    async refreshUserSelectedOb({ rootGetters, commit }) {
      const modelUserSelectedOb = rootGetters['dataView/modelUserSelectedOb']

      commit('SET_USER_SELECTED_OB', modelUserSelectedOb)
    },
    async resetChunkLineRecords({ state, commit }) {
      const chunkLineRecords = []

      state.chunkLineRecords.forEach(e => {
        const pos = e.split(',')

        pos[8] = 'blank'

        chunkLineRecords.push(pos.join(','))
      })

      commit('SET_CHUNK_LINE_RECORDS', chunkLineRecords)
    },
    async updateChunkLineRecords({ state, commit }, posRef) {
      const index = state.chunkLineRecords.indexOf(posRef)

      if (index === -1) {
        return null
      }

      const chunkLineRecords = [...state.chunkLineRecords]

      const pos = posRef.split(',')

      pos[8] = pos[8] === 'blank' ? 'green' : 'blank'

      chunkLineRecords.splice(index, 1)
      chunkLineRecords.push(pos.join(','))

      commit('SET_CHUNK_LINE_RECORDS', chunkLineRecords)

      return pos
    },
    generateAtmPatternTableRows({ state, commit }) {
      commit('dataView/SET_LOADING', true, { root: true })

      commit('SET_ATM_PATTERN_TABLE_ROWS', {})

      if (!state.atmPatterns.length) {
        return
      }

      const selectedAtmPatterns = [...state.selectedAtmPatterns]
      const updateStatusList = []
      const autoSelectedAtmPatterns = []
      const rows = {}

      state.atmPatterns.forEach(value => {
        const pos = [...value.pos]
        let { openBlock } = value
        const index = selectedAtmPatterns.map(e => e.pattern).indexOf(value.pattern)

        if (index !== -1) {
          openBlock = selectedAtmPatterns[index].multiLine === 'true' || openBlock

          updateStatusList.push([...pos])

          selectedAtmPatterns.splice(index, 1)
        }

        const status = pos[8]

        const checked = index !== -1 || status === 'green'

        if (index === -1 && checked) {
          updateStatusList.push([...pos])
        }

        if (index === -1 && status === 'green') {
          autoSelectedAtmPatterns.push({ ...value })
        }

        const updatedValue = {
          ...value,
          pos,
          openBlock,
          checked: index !== -1 || status === 'green',
          nitb: false,
        }

        if (rows[status]?.length) {
          rows[status].push(updatedValue)
        } else {
          rows[status] = [updatedValue]
        }
      })

      updateStatusList.forEach(pos => {
        bus.$emit('atm/updateStatus', { posRef: pos[7], autoChecked: true })
      })

      autoSelectedAtmPatterns.forEach(data => {
        bus.$emit('atm/updateSelectedAtmPattern', data)
      })

      commit('SET_ATM_PATTERN_TABLE_ROWS', rows)

      commit('dataView/SET_LOADING', false, { root: true })
    },
    resetAtmWizardTabs({ state, commit }) {
      const atmWizardTabs = { ...state.atmWizardTabs }

      Object.keys(atmWizardTabs).forEach(key => {
        if (key === 'tableRowSelection') {
          atmWizardTabs[key].active = true
        } else {
          atmWizardTabs[key].active = false
        }
      })

      commit('SET_ATM_WIZARD_TABS', atmWizardTabs)
    },
    resetStatusList({ state, commit }) {
      const atmAccordionOptions = [...state.atmAccordionOptions]

      atmAccordionOptions.forEach((_, index) => {
        atmAccordionOptions[index].visible = false
      })

      commit('SET_ATM_ACCORDION_OPTIONS', atmAccordionOptions)
    },
    reset({ rootGetters, commit, dispatch }) {
      const tableSettings = rootGetters['applicationSettings/tableSettings']

      commit('SET_ATM_CONFIG', tableSettings?.automatedTableModelConfig)
      commit('SET_CHUNK_LINE_RECORDS', [])
      commit('SET_USER_SELECTED_PATTERNS', [])
      commit('SET_EXTENDED_USER_SELECTED_PATTERNS', [])
      commit('SET_ATM_PATTERNS', [])
      commit('SET_SELECTED_ATM_PATTERNS', [])
      commit('SET_ATM_PATTERN_RECORDS', [])
      commit('SET_ATM_PATTERN_TABLE_ROWS', {})

      dispatch('resetAtmWizardTabs')
      dispatch('resetStatusList')
    },
  },
  getters: {
    atmWizardTabs(state) {
      return state.atmWizardTabs
    },
    atmAccordionOptions(state) {
      return state.atmAccordionOptions
    },
    atmConfig(state) {
      return state.atmConfig
    },
    chunkLineRecords(state) {
      return state.chunkLineRecords
    },
    userSelectedPatterns(state) {
      return state.userSelectedPatterns
    },
    extendedUserSelectedPatterns(state) {
      return state.extendedUserSelectedPatterns
    },
    userSelectedOb(state) {
      return state.userSelectedOb
    },
    atmPatterns(state) {
      return state.atmPatterns
    },
    selectedAtmPatterns(state) {
      return state.selectedAtmPatterns
    },
    atmPatternRecords(state) {
      return state.atmPatternRecords
    },
    atmPatternTableRows(state) {
      return state.atmPatternTableRows
    },
  },
}
