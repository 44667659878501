import axios from '@/rules-backend-axios'

export default {
  namespaced: true,
  state: {
    initialized: false,
    initializedDefinitionVersion: null,
    enableSubmit: false,
    results: [],
    resultIndex: null,
    search: null,
    executing: false,
    tableFields: {},
    options: {},
  },
  mutations: {
    SET_RESULTS(state, value) {
      state.results = value
    },
    SET_RESULT_INDEX(state, value) {
      state.resultIndex = value
    },
    SET_SEARCH(state, value) {
      state.search = value
    },
    SET_TABLE_FIELDS(state, value) {
      state.tableFields = value
    },
    SET_INITIALIZED(state, value) {
      state.initialized = value
    },
    SET_INITIALIZED_DEFINITION_VERSION(state, value) {
      state.initializedDefinitionVersion = value
    },
    SET_ENABlE_SUBMIT(state, value) {
      state.enableSubmit = value
    },
    SET_EXECUTING(state, value) {
      state.executing = value
    },
    SET_OPTIONS(state, value) {
      state.options = value
    },
  },
  actions: {
    async initialize({ commit, state, rootGetters }) {
      const { initialized, initializedDefinitionVersion } = state
      const selectedDefinitionVersion = rootGetters['dataView/selectedDefinitionVersion']

      if (initialized && initializedDefinitionVersion === selectedDefinitionVersion) {
        return
      }

      let tableFieldsResponse
      let optionsResponse
      try {
        tableFieldsResponse = await axios.get('/table_fields/', {
          params: {
            definition_version: selectedDefinitionVersion,
          },
        })
      } catch (error) {
        const loadingError = error?.response?.data?.detail || 'Error fetching lookup tables'
        throw new Error(loadingError)
      }

      try {
        optionsResponse = await axios.get('/options/')
      } catch (error) {
        const loadingError = error?.response?.data?.detail || 'Error fetching lookup options'
        throw new Error(loadingError)
      }

      const tableFields = {}
      tableFieldsResponse.data.data.forEach(tableField => {
        if (!tableFields[tableField.TABLE_NAME]) {
          tableFields[tableField.TABLE_NAME] = {
            columns: [],
          }
        }

        tableFields[tableField.TABLE_NAME].columns.push({
          name: tableField.COLUMN_NAME,
          type: tableField.DATA_TYPE,
        })
      })

      const optionsData = optionsResponse.data.data
      const options = {
        useMultipleEnvWiseDbs: optionsData.use_multiple_env_wise_dbs,
        environmentOptions: optionsData.environment_options,
      }

      commit('SET_TABLE_FIELDS', tableFields)
      commit('SET_OPTIONS', options)
      commit('SET_INITIALIZED_DEFINITION_VERSION', selectedDefinitionVersion)
      commit('SET_INITIALIZED', true)
    },
    reset({ dispatch }) {
      dispatch('resetResultData')
      dispatch('resetConfig')
    },
    resetResultData({ commit }) {
      commit('SET_RESULTS', [])
      commit('SET_RESULT_INDEX', null)
      commit('SET_SEARCH', null)
      commit('SET_ENABlE_SUBMIT', false)
      commit('SET_EXECUTING', false)
    },
    resetConfig({ commit }) {
      commit('SET_TABLE_FIELDS', {})
      commit('SET_OPTIONS', {})
      commit('SET_INITIALIZED', false)
      commit('SET_INITIALIZED_DEFINITION_VERSION', null)
    },
  },
  getters: {
    executing(state) {
      return state.executing
    },
    initialized(state) {
      return state.initialized
    },
    enableSubmit(state) {
      return state.enableSubmit
    },
    search(state) {
      return state.search
    },
    resultIndex(state) {
      return state.resultIndex
    },
    results(state) {
      return state.results
    },
    options(state) {
      return state.options
    },
    tables(state) {
      return Object.keys(state.tableFields)
    },
    tableColumns(state) {
      return tableName => {
        const fieldDetails = state.tableFields[tableName]
        if (!fieldDetails) {
          return []
        }
        return fieldDetails.columns
      }
    },
  },
}
