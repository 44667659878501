import axios from 'axios'
import getEnv from '@/utils/env'

const apiBaseUrl = `${getEnv('VUE_APP_RULES_BACKEND_URL')}/api`

const axiosIns = axios.create({
  baseURL: apiBaseUrl,
})

export default axiosIns
