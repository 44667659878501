import axios from 'axios'

export default {
  namespaced: true,
  state: {
    options: {},
    tableSettings: null,
    editableOptions: [],
    keySettings: null,
    otherSettings: null,
    keyRuleSettings: null,
    tableRuleSettings: null,
    tableNormalizerSettings: null,
    definitionVersions: [],
    defaultDefinitionVersion: null,
  },
  mutations: {
    SET_OPTIONS(state, data) {
      state.options = data
    },
    SET_TABLE_SETTINGS(state, data) {
      state.tableSettings = data
    },
    SET_EDITABLE_OPTIONS(state, data) {
      state.editableOptions = data
    },
    SET_KEY_SETTINGS(state, data) {
      state.keySettings = data
    },
    SET_OTHER_SETTINGS(state, data) {
      state.otherSettings = data
    },
    SET_KEY_RULE_SETTINGS(state, data) {
      state.keyRuleSettings = data
    },
    SET_TABLE_RULE_SETTINGS(state, data) {
      state.tableRuleSettings = data
    },
    SET_TABLE_NORMALIZER_SETTINGS(state, data) {
      state.tableNormalizerSettings = data
    },
    SET_DEFINITION_VERSIONS(state, data) {
      state.definitionVersions = data
    },
    SET_DEFAULT_DEFINITION_VERSION(state, data) {
      state.defaultDefinitionVersion = data
    },
  },
  actions: {
    async fetchApplicationSettings({ dispatch, commit, state }) {
      try {
        await dispatch('fetchData')

        commit('dataView/SET_SELECTED_DEFINITION_VERSION', state.defaultDefinitionVersion, { root: true })
        commit('atm/SET_ATM_CONFIG', state.tableSettings.automatedTableModelConfig, { root: true })
      } catch (error) {
        const err = error?.response?.data?.detail || 'Fetching Application Settings'

        throw new Error(err)
      }
    },
    async fetchData({ commit }) {
      const res = await axios.get('/application-settings/')

      commit('SET_OPTIONS', res.data.data.options)
      commit('SET_TABLE_SETTINGS', res.data.data.tableSettings)
      commit('SET_EDITABLE_OPTIONS', res.data.data.editableOptions)
      commit('SET_KEY_SETTINGS', res.data.data.keySettings)
      commit('SET_OTHER_SETTINGS', res.data.data.otherSettings)
      commit('SET_KEY_RULE_SETTINGS', res.data.data.keyRuleSettings)
      commit('SET_TABLE_RULE_SETTINGS', res.data.data.tableRuleSettings)
      commit('SET_TABLE_NORMALIZER_SETTINGS', res.data.data.tableNormalizerSettings)

      commit('SET_DEFINITION_VERSIONS', res.data.definition_versions)
      commit('SET_DEFAULT_DEFINITION_VERSION', res.data.default_definition_version)

      return res
    },
    async saveData({ commit, getters }) {
      const applicationSettings = getters.outputData

      const res = await axios.post('/application-settings/', { data: applicationSettings })

      commit('SET_OPTIONS', res.data.data.options)
      commit('SET_TABLE_SETTINGS', res.data.data.tableSettings)
      commit('SET_EDITABLE_OPTIONS', res.data.data.editableOptions)
      commit('SET_KEY_SETTINGS', res.data.data.keySettings)
      commit('SET_OTHER_SETTINGS', res.data.data.otherSettings)
      commit('SET_KEY_RULE_SETTINGS', res.data.data.keyRuleSettings)
      commit('SET_TABLE_RULE_SETTINGS', res.data.data.tableRuleSettings)
      commit('SET_TABLE_NORMALIZER_SETTINGS', res.data.data.tableNormalizerSettings)

      res.data.detail = 'Definition settings saved successfully'

      return res
    },
    reset({ commit }) {
      commit('SET_OPTIONS', {})
      commit('SET_TABLE_SETTINGS', null)
      commit('SET_EDITABLE_OPTIONS', [])
      commit('SET_KEY_SETTINGS', null)
      commit('SET_OTHER_SETTINGS', null)
      commit('SET_KEY_RULE_SETTINGS', null)
      commit('SET_TABLE_RULE_SETTINGS', null)
      commit('SET_TABLE_NORMALIZER_SETTINGS', null)

      commit('SET_DEFINITION_VERSIONS', [])
      commit('SET_DEFAULT_DEFINITION_VERSION', null)
    },
  },
  getters: {
    options(state) {
      return state.options
    },
    tableSettings(state) {
      return state.tableSettings
    },
    keySettings(state) {
      return state.keySettings
    },
    editableOptions(state) {
      return state.editableOptions
    },
    otherSettings(state) {
      return state.otherSettings
    },
    keyRuleSettings(state) {
      return state.keyRuleSettings
    },
    tableRuleSettings(state) {
      return state.tableRuleSettings
    },
    tableNormalizerSettings(state) {
      return state.tableNormalizerSettings
    },
    outputData(state) {
      const {
        options,
        tableSettings,
        keySettings,
        editableOptions,
        otherSettings,
        keyRuleSettings,
        tableRuleSettings,
        tableNormalizerSettings,
      } = state
      return {
        options,
        tableSettings,
        editableOptions,
        keySettings,
        otherSettings,
        keyRuleSettings,
        tableRuleSettings,
        tableNormalizerSettings,
      }
    },
    enableLookups(state) {
      return state.otherSettings?.enableLookups || false
    },
    definitionVersions(state) {
      return state.definitionVersions
    },
    defaultDefinitionVersion(state) {
      return state.defaultDefinitionVersion
    },
  },
}
