import axios from 'axios'
import { cloneDeep } from 'lodash'

export default {
  namespaced: true,
  state: {
    options: {},
    keyOptionsForRules: [],
    editableOptions: [],
    keyQualifiers: [],
    compoundKeys: [],
    projectOptions: [],
    project: null,
  },
  mutations: {
    SET_OPTIONS(state, data) {
      state.options = data
    },
    SET_KEY_OPTIONS_FOR_RULES(state, data) {
      state.keyOptionsForRules = data
    },
    SET_EDITABLE_OPTIONS(state, data) {
      state.editableOptions = data
    },
    SET_KEY_QUALIFIERS(state, data) {
      state.keyQualifiers = data
    },
    SET_COMPOUND_KEYS(state, data) {
      state.compoundKeys = data
    },
    SET_PROJECT_OPTIONS(state, data) {
      state.projectOptions = data
    },
    SET_PROJECT(state, data) {
      state.project = data
    },
  },
  actions: {
    async fetchDefinitionSettings({ dispatch }) {
      try {
        await dispatch('fetchData')
        await dispatch('setKeyOptionsForRules')
      } catch (error) {
        const err = error?.response?.data?.detail || 'Fetching Definition Settings'

        throw new Error(err)
      }
    },
    async fetchData({ rootGetters, commit, state }) {
      const params = {
        project: state.project || rootGetters['batch/batch']?.project,
      }

      const res = await axios.get('/get_definition_settings/', { params })

      commit('SET_OPTIONS', res.data.options)
      commit('SET_EDITABLE_OPTIONS', res.data.editableOptions)
      commit('SET_KEY_QUALIFIERS', res.data.keyQualifiers)
      commit('SET_COMPOUND_KEYS', res.data.compoundKeys || [])

      return res
    },
    async saveData({
      rootGetters, state, commit, getters,
    }) {
      const definitionSettings = getters.outputData

      const data = {
        project: state.project || rootGetters['batch/batch']?.project,
        definition_settings: definitionSettings,
      }

      const res = await axios.post('/update_definition_settings/', data)

      commit('SET_OPTIONS', res.data.data.options)
      commit('SET_EDITABLE_OPTIONS', res.data.data.editableOptions)
      commit('SET_KEY_QUALIFIERS', res.data.data.keyQualifiers)
      commit('SET_COMPOUND_KEYS', res.data.data.compoundKeys || [])

      res.data.detail = 'Definition settings saved successfully'

      return res
    },
    setKeyOptionsForRules({
      rootGetters, state, commit, getters,
    }) {
      const applicableFor = rootGetters['batch/view'] === 'key' ? 'keys' : 'table'
      const keyOptions = getters.keyOptions(applicableFor)
      const keyOptionsForRules = []

      keyOptions.forEach(e => {
        if (e.qualifier !== '' || e.compoundKeys !== '') {
          return
        }

        keyOptionsForRules.push({
          label: e.keyValue,
          value: {
            fieldInfo: e,
            parent: null,
          },
        })
      })

      state.keyQualifiers.forEach(item => {
        item.options.forEach(e => {
          keyOptionsForRules.push({
            label: `${item.name} - ${e.value}`,
            value: {
              fieldInfo: {
                keyValue: e.value,
                keyLabel: e.label,
              },
              parent: item.name,
            },
          })
        })
      })
      if (applicableFor === 'keys') {
        state.compoundKeys.forEach(item => {
          item.keyItems.forEach(e => {
            keyOptionsForRules.push({
              label: `${item.name} - ${e.keyValue}`,
              value: {
                fieldInfo: e,
                parent: item.name,
              },
            })
          })
        })
      }

      commit('SET_KEY_OPTIONS_FOR_RULES', keyOptionsForRules)
    },
    reset({ commit }) {
      commit('SET_OPTIONS', {})
      commit('SET_EDITABLE_OPTIONS', [])
      commit('SET_KEY_QUALIFIERS', [])
      commit('SET_COMPOUND_KEYS', [])
      commit('SET_PROJECT_OPTIONS', [])
      commit('SET_PROJECT', null)
    },
  },
  getters: {
    options(state) {
      return state.options
    },
    sortedOptions(state) {
      const options = cloneDeep(state.options)

      if (options['options-keys']) {
        options['options-keys'].items.sort((a, b) => {
          const valueA = a.keyLabel.toUpperCase()
          const valueB = b.keyLabel.toUpperCase()

          if (valueA < valueB) { return -1 }
          if (valueA > valueB) { return 1 }
          return 0
        })
      }

      return options
    },
    keyTypeAvailablity(state) {
      const availability = {}
      const colTypeOptions = state.options['options-col-type']

      if (!colTypeOptions) {
        return availability
      }

      colTypeOptions.items.forEach(colTypeOptionItem => {
        const key = colTypeOptionItem[colTypeOptions.valueKey]
        availability[key] = {
          applicableForKeys: colTypeOptionItem.applicableForKeys,
          applicableForTable: colTypeOptionItem.applicableForTable,
        }
      })

      return availability
    },
    keyOptions(state, getters) {
      return applicableFor => {
        const keyOptions = getters.sortedOptions['options-keys']
        const { keyTypeAvailablity } = getters
        const checkKey = applicableFor === 'keys' ? 'applicableForKeys' : 'applicableForTable'
        // const checkKey = applicableFor === 'keys' ? ['table'] : ['key', 'compound', 'addressBlock', 'addressBlockPartial']

        if (!keyOptions) {
          return []
        }

        return keyOptions.items.filter(keyOptionItem => {
          if (!keyOptionItem.type) {
            return true
          }
          // return !checkKey.includes(keyOptionItem.type)
          return keyTypeAvailablity[keyOptionItem.type]?.[checkKey]
        })
      }
    },
    keyOptionsForRules(state) {
      return state.keyOptionsForRules
    },
    keyOptionsApplicableForKeys(state, getters) {
      return getters.keyOptions('keys')
    },
    keyOptionsApplicableForTable(state, getters) {
      return getters.keyOptions('table')
    },
    keyQualifiers(state) {
      return state.keyQualifiers
    },
    editableOptions(state) {
      return state.editableOptions
    },
    outputData(state) {
      const {
        options,
        keyQualifiers,
        editableOptions,
        compoundKeys,
      } = state

      return {
        options,
        editableOptions,
        keyQualifiers,
        compoundKeys,
      }
    },
    compoundKeys(state) {
      return state.compoundKeys
    },
    projectOptions(state) {
      return state.projectOptions
    },
    project(state) {
      return state.project
    },
  },
}
