import Vue from 'vue'
import { cloneDeep } from 'lodash'
import axios from 'axios'
import WS from '@/utils/ws'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getNewTable, getDefinitionKey } from './helper'

export default {
  namespaced: true,
  state: {
    defaultModes: {
      keySettings: 'key-items',
      tableSettings: 'table-models',
      chunkData: 'chunk-data',
      automatedTableModel: 'automated-table-model',
      verification: 'verification',
      definedKeys: 'defined-keys',
    },
    mainMode: '',
    mode: '',
    allDefinitions: [],
    typesByDefinition: [],
    batchesByDefinitionType: [],
    selectedDefinition: null,
    dJsonTableList: [],
    selectedTableId: 0,
    displayNotInUseFields: true,
    keyRuleItem: null,
    keyRuleItemEditIndex: -1,
    tableRuleItem: null,
    tableRuleItemEditIndex: -1,
    keyLookupItem: null,
    keyLookupItemEditIndex: -1,
    loading: false,
    loadingError: null,
    selectedDefinitionVersion: null,
    isfullCellLabel: true,
  },
  mutations: {
    SET_MAIN_MODE(state, value) {
      state.mainMode = value
    },
    SET_MODE(state, value) {
      state.mode = value
    },
    SET_ALL_DEFINITIONS(state, value) {
      state.allDefinitions = value
    },
    SET_TYPES_BY_DEFINITION(state, value) {
      state.typesByDefinition = value
    },
    SET_BATCHES_BY_DEFINITION_TYPE(state, value) {
      state.batchesByDefinitionType = value
    },
    SET_SELECTED_DEFINITION(state, value) {
      state.selectedDefinition = value
    },
    SET_D_JSON_TABLE_LIST(state, value) {
      state.dJsonTableList = value
    },
    SET_SELECTED_TABLE_ID(state, value) {
      state.selectedTableId = value
    },
    SET_TABLE_MODELS(state, value) {
      state.selectedDefinition.table[state.selectedTableId].table_definition_data.models = value
    },
    SET_TABLE_COLUMNS(state, value) {
      state.selectedDefinition.table[state.selectedTableId].table_definition_data.columns = value
    },
    SET_TABLE_KEY_ITEMS(state, value) {
      state.selectedDefinition.table[state.selectedTableId].table_definition_data.keyItems = value
    },
    SET_TABLE_RULE_ITEMS(state, value) {
      state.selectedDefinition.table[state.selectedTableId].table_definition_data.ruleItems = value
    },
    SET_TABLE_NORMALIZER_ITEMS(state, value) {
      state.selectedDefinition.table[state.selectedTableId].table_definition_data.normalizerItems = value
    },
    SET_KEY_MODELS(state, value) {
      state.selectedDefinition.key.models = value
    },
    SET_KEY_ITEMS(state, value) {
      state.selectedDefinition.key.items = value
    },
    SET_KEY_RULE_ITEMS(state, value) {
      state.selectedDefinition.key.ruleItems = value
    },
    SET_KEY_NOT_IN_USE_ITEMS(state, value) {
      state.selectedDefinition.key.notInUseItems = value
    },
    SET_KEY_LOOKUP_ITEMS(state, value) {
      state.selectedDefinition.key.lookupItems = value
    },
    SET_DISPLAY_NOT_IN_USE_FIELDS(state, value) {
      state.displayNotInUseFields = value
    },
    SET_KEY_RULE_ITEM(state, value) {
      state.keyRuleItem = value
    },
    SET_KEY_RULE_ITEM_EDIT_INDEX(state, value) {
      state.keyRuleItemEditIndex = value
    },
    SET_KEY_RULE_ITEM_RULES(state, value) {
      state.keyRuleItem.rules = value
    },
    SET_TABLE_RULE_ITEM(state, value) {
      state.tableRuleItem = value
    },
    SET_TABLE_RULE_ITEM_EDIT_INDEX(state, value) {
      state.tableRuleItemEditIndex = value
    },
    SET_TABLE_RULE_ITEM_RULES(state, value) {
      state.tableRuleItem.rules = value
    },
    SET_KEY_LOOKUP_ITEM(state, value) {
      state.keyLookupItem = value
    },
    SET_KEY_LOOKUP_ITEM_EDIT_INDEX(state, value) {
      state.keyLookupItemEditIndex = value
    },
    SET_KEY_LOOKUP_ITEM_QUERIES(state, value) {
      state.keyLookupItem.queries = value
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_LOADING_ERROR(state, value) {
      state.loadingError = value
    },
    SET_SELECTED_DEFINITION_VERSION(state, value) {
      state.selectedDefinitionVersion = value
    },
    SET_MODEL_AUTO_PATTERNS(state, value) {
      state.selectedDefinition.table[state.selectedTableId].table_definition_data.models.autoPatterns = value
    },
    SET_MODEL_USER_SELECTED_PATTERNS(state, value) {
      state.selectedDefinition.table[state.selectedTableId].table_definition_data.models.userSelectedPatterns = value
    },
    SET_MODEL_EXTENDED_USER_SELECTED_PATTERNS(state, value) {
      state.selectedDefinition.table[state.selectedTableId].table_definition_data.models.extendedUserSelectedPatterns = value
    },
    SET_MODEL_USER_SELECTED_OB(state, value) {
      state.selectedDefinition.table[state.selectedTableId].table_definition_data.models.userSelectedOb = value
    },
    SET_MODEL_MULTIPLE_LINE_RECORD(state, value) {
      state.selectedDefinition.table[state.selectedTableId].table_definition_data.models.multipleLineRecord = value
    },
    SET_MODEL_AUTO_TYPE(state, value) {
      state.selectedDefinition.table[state.selectedTableId].table_definition_data.models.type = value
    },
    SET_IS_FULL_CELL_LABEL(state, value) {
      state.isfullCellLabel = value
    },
  },
  actions: {
    async setDJsonTableList({ rootGetters, commit }) {
      const verificationDetails = rootGetters['batch/verificationDetails']
      const selectedDocumentId = rootGetters['batch/selectedDocumentId']

      const batchId = `${selectedDocumentId.split('.')[0]}.${selectedDocumentId.split('.')[1]}`
      const batch = verificationDetails.find(e => e.id === batchId)
      const documentData = batch.data_json.nodes.find(e => e.id === selectedDocumentId)

      const dJsonTableList = []

      if (documentData) {
        documentData.children.forEach(e => {
          if (e.type === 'table') {
            dJsonTableList.push({
              table_id: e.table_id,
              table_name: e.table_name,
              table_unique_id: e.table_unique_id,
            })
          }
        })
      }

      dJsonTableList.sort((a, b) => a.table_id - b.table_id)

      commit('SET_D_JSON_TABLE_LIST', dJsonTableList)
    },
    async fetchAllDefinitions({ rootGetters, commit, state }) {
      if (state.allDefinitions.length) {
        return
      }

      try {
        const selectedProjectCountries = rootGetters['auth/selectedProjectCountries']

        const res = await axios.post('/all_definitions/', {
          project_countries: selectedProjectCountries.map(e => `${e.countryCode}_${e.project}`).join(','),
        })

        commit('SET_ALL_DEFINITIONS', res.data)
      } catch (error) {
        const err = error?.response?.data?.detail || 'Fetching all Definitions'

        throw new Error(err)
      }
    },
    async fetchSelectorDataByBatch({ state, dispatch }, payload) {
      const data = {
        definitionId: payload.definition_id,
        definitionType: payload.type,
      }

      if (!state.typesByDefinition.length) {
        await dispatch('fetchTypesByDefinition', data.definitionId)
      }

      if (!state.batchesByDefinitionType.length) {
        await dispatch('fetchBatchesByDefinitionType', data)
      }
    },
    async fetchTypesByDefinition({ commit }, definitionId) {
      try {
        const res = await axios.get('/get_types_by_definition/', {
          params: {
            definition_id: definitionId,
          },
        })

        commit('SET_TYPES_BY_DEFINITION', res.data.sort())
      } catch (error) {
        const err = error?.response?.data?.detail || 'Error fetching types by definition'

        commit('batch/SET_ERROR', err, { root: true })
        commit('SET_LOADING', false)
      }
    },
    async fetchBatchesByDefinitionType({ commit }, payload) {
      try {
        const res = await axios.get('/get_batches_by_definition_type/', {
          params: {
            definition_id: payload.definitionId,
            definition_type: payload.definitionType,
          },
        })

        commit('SET_BATCHES_BY_DEFINITION_TYPE', res.data.sort())
      } catch (error) {
        const err = error?.response?.data?.detail || 'Error fetching batches'

        commit('batch/SET_ERROR', err, { root: true })
        commit('SET_LOADING', false)
      }
    },
    async fetchDefinition({ dispatch, rootGetters, state }, currentRouteName) {
      let url = ''
      let params = {}
      const batch = rootGetters['batch/batch']
      if (currentRouteName === 'template-batch') {
        url = '/dashboard/get_template_definition/'
        params = {
          template_name: batch.definitionId,
          definition_version: state.selectedDefinitionVersion,
        }
      } else {
        url = '/search_definition/'
        params = {
          definition_id: batch.definitionId,
          type: batch.type,
          name_matching_text: batch.nameMatchingText,
          definition_version: state.selectedDefinitionVersion,
        }
      }
      try {
        const res = await axios.get(url, {
          params,
        })

        await dispatch('setSelectedDefinition', res.data)
      } catch (error) {
        const err = error?.response?.data?.detail || 'Fetching Definition'

        throw new Error(err)
      }
    },
    setSelectedDefinition({
      rootGetters, commit, state, dispatch,
    }, data) {
      let definition = data

      if (definition != null) {
        const tableSettings = rootGetters['applicationSettings/tableSettings']
        let table

        if (Array.isArray(data?.table) && data.table.length) {
          table = data.table

          table.forEach((item, index) => {
            if (Object.keys(item.table_definition_data.models).length === 0) {
              const newTable = getNewTable(tableSettings)

              table[index].table_definition_data.models = newTable.table_definition_data.models
            } else if (!('autoPatterns' in item.table_definition_data.models)) {
              table[index].table_definition_data.models.autoPatterns = []
            }
          })
        } else {
          table = [getNewTable(tableSettings)]
        }

        definition = {
          ...data,
          table,
          key: getDefinitionKey(data),
        }
      }
      commit('SET_SELECTED_DEFINITION', definition)

      // Refresh data for rules and lookup screens
      if (definition && state.keyRuleItemEditIndex !== -1) {
        dispatch('setKeyRuleItemByIndex', state.keyRuleItemEditIndex)
      }
      if (definition && state.tableRuleItemEditIndex !== -1) {
        dispatch('setTableRuleItemByIndex', state.tableRuleItemEditIndex)
      }
      if (definition && state.keyLookupItemEditIndex !== -1) {
        dispatch('setKeyLookupItemByIndex', state.keyLookupItemEditIndex)
      }
    },
    async onChangeDefinition({ rootGetters, dispatch, commit }, definitionId) {
      commit('SET_LOADING', true)

      const selectedProjectCountries = rootGetters['auth/selectedProjectCountries']

      const res = await axios.post('/latest_batch_info/', {
        definition_id: definitionId,
        project_countries: selectedProjectCountries.map(e => `${e.countryCode}_${e.project}`).join(','),
      })

      if (!res.data.batch_id) {
        commit('SET_LOADING', false)

        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: `No batch available for this "${definitionId}" profile`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return
      }

      commit('SET_BATCHES_BY_DEFINITION_TYPE', [])
      await dispatch('atm/reset', null, { root: true })

      await dispatch('fetchTypesByDefinition', definitionId)
      await dispatch('onChangeBatch', { batchId: res.data.batch_id, refresh: true })

      commit('SET_LOADING', false)
    },
    async onChangeDefinitionType({ state, dispatch, commit }, definitionType) {
      commit('SET_LOADING', true)

      const definitionId = state.selectedDefinition.definition_id

      const batchesByDefinitionType = [...state.batchesByDefinitionType]

      await dispatch('fetchBatchesByDefinitionType', { definitionId, definitionType })

      if (!state.batchesByDefinitionType.length) {
        commit('SET_BATCHES_BY_DEFINITION_TYPE', batchesByDefinitionType)
        commit('SET_LOADING', false)

        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: `No batch available for this "${definitionId} - ${definitionType}" profile`,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        return
      }

      await dispatch('atm/reset', null, { root: true })

      const [selectedBatch] = state.batchesByDefinitionType

      await dispatch('onChangeBatch', { batchId: selectedBatch, refresh: true })

      commit('SET_LOADING', false)
    },
    async onChangeBatch({
      rootGetters, getters, dispatch, commit, state,
    }, payload) {
      commit('SET_LOADING', true)

      try {
        commit('SET_SELECTED_TABLE_ID', state.selectedTableId)

        WS.leaveRoom(`batch_status_${rootGetters['batch/batch'].id}`)

        await dispatch('batch/fetchBatch', payload.batchId, { root: true })

        if (payload.refresh) {
          await dispatch('fetchDefinition')
        }
        const currentRouteName = rootGetters['app/currentRouteName']
        // await dispatch('batch/fetchPositionShiftData', null, { root: true })
        if (currentRouteName !== 'template-batch') {
          await dispatch('atm/fetchAtmChunkData', null, { root: true })
        }

        if (getters.modelMultipleLineRecord) {
          await dispatch('atm/refreshExtendUserSelectedPatterns', null, { root: true })
        } else {
          await dispatch('atm/refreshUserSelectedPatterns', null, { root: true })
        }

        await dispatch('atm/refreshUserSelectedOb', null, { root: true })

        if (rootGetters['batch/view'] === 'chunk-data') {
          await dispatch('batch/loadChunkData', null, { root: true })
        }

        WS.joinRoom(`batch_status_${payload.batchId}`)

        commit('SET_LOADING', false)
      } catch (error) {
        commit('batch/SET_ERROR', error, { root: true })
        commit('SET_LOADING', false)
      }
    },
    reset({ commit, dispatch }) {
      commit('SET_MAIN_MODE', '')
      commit('SET_MODE', '')
      commit('SET_ALL_DEFINITIONS', [])
      commit('SET_TYPES_BY_DEFINITION', [])
      commit('SET_BATCHES_BY_DEFINITION_TYPE', [])
      commit('SET_SELECTED_DEFINITION', null)
      commit('SET_SELECTED_TABLE_ID', 0)
      commit('SET_DISPLAY_NOT_IN_USE_FIELDS', true)
      commit('SET_KEY_RULE_ITEM', null)
      commit('SET_LOADING', false)
      commit('SET_LOADING_ERROR', null)
      commit('SET_KEY_RULE_ITEM_EDIT_INDEX', -1)
      commit('SET_TABLE_RULE_ITEM', null)
      commit('SET_TABLE_RULE_ITEM_EDIT_INDEX', -1)
      commit('SET_SELECTED_DEFINITION_VERSION', null)
      commit('SET_KEY_LOOKUP_ITEM', null)
      commit('SET_KEY_LOOKUP_ITEM_EDIT_INDEX', -1)

      dispatch('atm/reset', null, { root: true })
    },
    async selectFirstMode({ rootGetters, dispatch }) {
      const batchView = rootGetters['batch/view']

      let mainMode = ''

      if (batchView === 'key') {
        mainMode = 'keySettings'
      } else if (batchView === 'table') {
        mainMode = 'tableSettings'
      } else if (batchView === 'chunk-data') {
        mainMode = 'chunkData'
      } else if (batchView === 'analyzer') {
        mainMode = 'automatedTableModel'
      }

      await dispatch('setMainMode', mainMode)
    },
    async setMainMode({ commit, state, dispatch }, mainMode) {
      commit('SET_MAIN_MODE', mainMode)
      await dispatch('setMode', state.defaultModes[mainMode])
    },
    setMode({ commit, state }, mode) {
      commit('SET_MODE', mode)
      if (mode !== 'key-rules' && state.keyRuleItem) {
        commit('SET_KEY_RULE_ITEM', null)
        commit('SET_KEY_RULE_ITEM_EDIT_INDEX', -1)
      }
      if (mode !== 'table-rules' && state.tableRuleItem) {
        commit('SET_TABLE_RULE_ITEM', null)
        commit('SET_TABLE_RULE_ITEM_EDIT_INDEX', -1)
      }
      if (mode !== 'key-lookup' && state.keyLookupItem) {
        commit('SET_KEY_LOOKUP_ITEM', null)
        commit('SET_KEY_LOOKUP_ITEM_EDIT_INDEX', -1)
      }
    },
    setKeyRuleItem({ commit, getters }, { id, keyId }) {
      let editIndex = -1
      const { keyRuleItems } = getters
      let record

      let keyRuleItemIndex = keyRuleItems.findIndex(keyRuleItem => keyRuleItem.id === id && keyRuleItem.keyId === keyId)
      if (keyRuleItemIndex !== -1) {
        record = cloneDeep(keyRuleItems[keyRuleItemIndex])
        editIndex = keyRuleItemIndex
      } else {
        keyRuleItemIndex = keyRuleItems.findIndex(keyRuleItem => keyRuleItem.id === id && !keyRuleItem.keyId)
        if (keyRuleItemIndex !== -1) {
          record = cloneDeep(keyRuleItems[keyRuleItemIndex])
          record.keyId = keyId
          editIndex = keyRuleItemIndex
        } else {
          record = {
            id,
            keyId,
            rules: [],
          }
        }
      }
      commit('SET_KEY_RULE_ITEM_EDIT_INDEX', editIndex)
      commit('SET_KEY_RULE_ITEM', record)
    },
    setKeyRuleItemByIndex({ commit, getters }, index) {
      commit('SET_KEY_RULE_ITEM_EDIT_INDEX', index)
      commit('SET_KEY_RULE_ITEM', cloneDeep(getters.keyRuleItems[index]))
    },
    async saveKeyRuleItem({
      commit, state, getters, dispatch,
    }) {
      const keyRuleItems = cloneDeep(getters.keyRuleItems)
      const record = state.keyRuleItem
      const editIndex = state.keyRuleItemEditIndex

      if (editIndex === -1 && record.rules.length) {
        keyRuleItems.push(record)
        commit('SET_KEY_RULE_ITEM_EDIT_INDEX', keyRuleItems.length - 1)
      }

      if (editIndex !== -1 && record.rules.length) {
        keyRuleItems[editIndex] = record
      }

      if (editIndex !== -1 && !record.rules.length) {
        await dispatch('setMode', 'key-rule-items')
        keyRuleItems.splice(editIndex, 1)
      }

      commit('SET_KEY_RULE_ITEMS', keyRuleItems)
    },
    addTable({ rootGetters, commit, getters }, { tableName, tableId }) {
      const { selectedDefinition } = getters
      const tableSettings = rootGetters['applicationSettings/tableSettings']

      const newTable = getNewTable(tableSettings, tableId, tableName)

      selectedDefinition.table.push(newTable)

      commit('SET_SELECTED_DEFINITION', getters.selectedDefinition)
    },
    renameTable({ commit, getters }, { tableName, tableId }) {
      const { selectedDefinition } = getters

      selectedDefinition.table[tableId].table_name = tableName

      commit('SET_SELECTED_DEFINITION', selectedDefinition)
    },
    deleteTable({ commit, getters }, { tableId }) {
      const { selectedDefinition } = getters

      selectedDefinition.table = selectedDefinition.table.filter(i => i.table_id !== tableId)

      selectedDefinition.table.forEach((item, index) => {
        selectedDefinition.table[index].table_id = index
      })

      commit('SET_SELECTED_DEFINITION', selectedDefinition)
    },
    setTableRuleItem({ commit, getters }, { label }) {
      let editIndex = -1
      const { tableRuleItems } = getters
      let record

      const tableRuleItemIndex = tableRuleItems.findIndex(tableRuleItem => tableRuleItem.label === label)
      if (tableRuleItemIndex !== -1) {
        record = cloneDeep(tableRuleItems[tableRuleItemIndex])
        editIndex = tableRuleItemIndex
      } else {
        record = {
          label,
          rules: [],
        }
      }
      commit('SET_TABLE_RULE_ITEM_EDIT_INDEX', editIndex)
      commit('SET_TABLE_RULE_ITEM', record)
    },
    setTableRuleItemByIndex({ commit, getters }, index) {
      commit('SET_TABLE_RULE_ITEM_EDIT_INDEX', index)
      commit('SET_TABLE_RULE_ITEM', cloneDeep(getters.tableRuleItems[index]))
    },
    async saveTableRuleItem({
      commit, state, getters, dispatch,
    }) {
      const tableRuleItems = cloneDeep(getters.tableRuleItems)
      const record = state.tableRuleItem
      const editIndex = state.tableRuleItemEditIndex

      if (editIndex === -1 && record.rules.length) {
        tableRuleItems.push(record)
        commit('SET_TABLE_RULE_ITEM_EDIT_INDEX', tableRuleItems.length - 1)
      }

      if (editIndex !== -1 && record.rules.length) {
        tableRuleItems[editIndex] = record
      }

      if (editIndex !== -1 && !record.rules.length) {
        await dispatch('setMode', 'table-rule-items')
        tableRuleItems.splice(editIndex, 1)
      }

      commit('SET_TABLE_RULE_ITEMS', tableRuleItems)
    },
    setKeyLookupItem({ commit, getters }, { keyId, nestedLabel }) {
      let editIndex = -1
      const { keyLookupItems } = getters
      let record

      const keyLookupItemIndex = keyLookupItems.findIndex(item => item.nestedLabel === nestedLabel && item.keyId === keyId)
      if (keyLookupItemIndex !== -1) {
        record = cloneDeep(keyLookupItems[keyLookupItemIndex])
        editIndex = keyLookupItemIndex
      } else {
        record = {
          keyId,
          nestedLabel,
          queries: [],
        }
      }
      commit('SET_KEY_LOOKUP_ITEM_EDIT_INDEX', editIndex)
      commit('SET_KEY_LOOKUP_ITEM', record)
    },
    setKeyLookupItemByIndex({ commit, getters }, index) {
      commit('SET_KEY_LOOKUP_ITEM_EDIT_INDEX', index)
      commit('SET_KEY_LOOKUP_ITEM', cloneDeep(getters.keyLookupItems[index]))
    },
    saveKeyLookupItem({ commit, state, getters }) {
      const keyLookupItems = cloneDeep(getters.keyLookupItems)
      const record = state.keyLookupItem
      const editIndex = state.keyLookupItemEditIndex

      if (editIndex === -1) {
        keyLookupItems.push(record)
        commit('SET_KEY_LOOKUP_ITEM_EDIT_INDEX', keyLookupItems.length - 1)
      } else {
        keyLookupItems[editIndex] = record
      }
      commit('SET_KEY_LOOKUP_ITEMS', keyLookupItems)
    },
  },
  getters: {
    mainMode(state) {
      return state.mainMode
    },
    mode(state) {
      return state.mode
    },
    allDefinitions(state) {
      return state.allDefinitions
    },
    typesByDefinition(state) {
      return state.typesByDefinition
    },
    batchesByDefinitionType(state) {
      return state.batchesByDefinitionType
    },
    selectedDefinition(state) {
      return state.selectedDefinition
    },
    selectedTableId(state) {
      return state.selectedTableId
    },
    table(state) {
      if (state.mainMode === 'verification') {
        return state.dJsonTableList
      }

      return state.selectedDefinition?.table
    },
    tableModels(state) {
      return state.selectedDefinition?.table[state.selectedTableId]?.table_definition_data.models
    },
    tableColumns(state) {
      return state.selectedDefinition?.table[state.selectedTableId]?.table_definition_data.columns
    },
    tableKeyItems(state) {
      return state.selectedDefinition?.table[state.selectedTableId]?.table_definition_data.keyItems
    },
    tableRuleItems(state) {
      return state.selectedDefinition?.table[state.selectedTableId]?.table_definition_data.ruleItems
    },
    tableNormalizerItems(state) {
      return state.selectedDefinition?.table[state.selectedTableId]?.table_definition_data.normalizerItems
    },
    key(state) {
      return state.selectedDefinition?.key
    },
    keyItems(state) {
      return state.selectedDefinition?.key.items
    },
    keyModels(state) {
      return state.selectedDefinition?.key.models
    },
    keyRuleItems(state) {
      return state.selectedDefinition?.key.ruleItems
    },
    keyNotInUseItems(state) {
      return state.selectedDefinition?.key.notInUseItems
    },
    keyLookupItems(state) {
      return state.selectedDefinition?.key.lookupItems
    },
    displayNotInUseFields(state) {
      return state.displayNotInUseFields
    },
    keyRuleItem(state) {
      return state.keyRuleItem
    },
    keyRuleItemEditIndex(state) {
      return state.keyRuleItemEditIndex
    },
    keyRuleItemRules(state) {
      return state.keyRuleItem?.rules || []
    },
    tableRuleItem(state) {
      return state.tableRuleItem
    },
    tableRuleItemEditIndex(state) {
      return state.tableRuleItemEditIndex
    },
    tableRuleItemRules(state) {
      return state.tableRuleItem?.rules || []
    },
    keyLookupItem(state) {
      return state.keyLookupItem
    },
    keyLookupItemQueries(state) {
      return state.keyLookupItem?.queries || []
    },
    loading(state) {
      return state.loading
    },
    loadingError(state) {
      return state.loadingError
    },
    selectedDefinitionVersion(state) {
      return state.selectedDefinitionVersion
    },
    selectedModelType(state) {
      return state.selectedDefinition.table[state.selectedTableId].table_definition_data.models.type
    },
    modelAutoPattern(state) {
      return state.selectedDefinition.table[state.selectedTableId].table_definition_data.models.autoPatterns
    },
    modelUserSelectedPatterns(state) {
      return state.selectedDefinition.table[state.selectedTableId].table_definition_data.models?.userSelectedPatterns || []
    },
    modelExtendedUserSelectedPatterns(state) {
      return state.selectedDefinition.table[state.selectedTableId].table_definition_data.models?.extendedUserSelectedPatterns || []
    },
    modelUserSelectedOb(state) {
      return state.selectedDefinition.table[state.selectedTableId].table_definition_data.models?.userSelectedOb || []
    },
    modelAutoPositionShiftCal(state, getters) {
      return getters.table[state.selectedTableId].table_definition_data.models?.autoPositionShiftCal
    },
    modelMultipleLineRecord(state) {
      return state.selectedDefinition.table[state.selectedTableId].table_definition_data.models?.multipleLineRecord || false
    },
    showChunkData(state) {
      return state.showChunkData
    },
    getIsfullCellLabel(state) {
      return state.isfullCellLabel
    },
  },
}
