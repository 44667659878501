import getEnv from '@/utils/env'

const search = (nodes, searchVal, parentIds = []) => {
  let expandedIds = []
  let matchedIds = []

  nodes.forEach(node => {
    if (node.type === 'word' || node.type === 'words') {
      const nodeText = String(node.v).toLowerCase()
      if (nodeText.includes(searchVal)) {
        expandedIds = expandedIds.concat(parentIds)
        expandedIds.push(node.id)
        matchedIds.push(node.id)
      }
    }
    if (node.children && node.children.length > 0) {
      const searchResult = search(node.children, searchVal, [...parentIds, node.id])

      expandedIds = expandedIds.concat(searchResult.expandedIds)
      matchedIds = matchedIds.concat(searchResult.matchedIds)
    }
  })

  return {
    expandedIds,
    matchedIds,
  }
}

const searchNodes = (nodes, searchValue) => {
  const searchResult = search(nodes, searchValue.toLowerCase())
  const expandedIds = [...new Set(searchResult.expandedIds)]
  return {
    expandedIds,
    matchedIds: searchResult.matchedIds,
  }
}

const expandByType = (nodes, nodeTypes, parentIds = []) => {
  let expandedIds = []

  nodes.forEach(node => {
    if (nodeTypes.includes(node.type)) {
      expandedIds = expandedIds.concat(parentIds)
      expandedIds.push(node.id)
    }
    if (node.children && node.children.length > 0) {
      expandedIds = expandedIds.concat(expandByType(node.children, nodeTypes, [...parentIds, node.id]))
    }
  })

  return expandedIds
}

const expandNodesByType = (nodes, nodeTypes) => {
  let expandedIds = expandByType(nodes, nodeTypes)
  expandedIds = [...new Set(expandedIds)]
  return expandedIds
}

// Functions for backward compatibility for old data format
const getNodeValue = node => node.v || node.value || ''
const getNodeLabel = node => node.label || node.key || ''
const getNodeLabelKey = node => (node.label ? 'label' : 'key')
const getNodeChildren = node => node.children || []

const getKeyNodeTitle = (node, nodeIndex) => {
  let title
  if (node.type === 'root') {
    title = node.id
  } else if (node.type === 'document') {
    title = `${node.id} - ${node.DocType}`
  } else if (['key_detail', 'key_detail_robot', 'key_detail_static'].includes(node.type)) {
    const childNameNode = node.children?.find(e => e[getNodeLabelKey(e)] === 'name')
    title = childNameNode ? getNodeValue(childNameNode) : getNodeValue(node)
  } else if (['keyTextDetail', 'keyTextDetailRobot', 'keyTextDetailStatic'].includes(node.type)) {
    title = getNodeValue(node)
  } else {
    title = `${node.type.charAt(0).toUpperCase() + node.type.slice(1)} - ${nodeIndex}`
  }
  return title
}

const getAddToDBData = (node, nestedLabel) => {
  const nodeDetails = {}
  getNodeChildren(node).forEach(childNode => {
    const childNodeLabel = getNodeLabel(childNode)
    const childNodeValue = getKeyNodeTitle(childNode)
    nodeDetails[childNodeLabel] = childNodeValue
  })

  const record = {}
  const tablePrefix = nestedLabel.toUpperCase()
  const tableName = `${tablePrefix}_MASTER`
  const fields = ['name', 'addressLine1', 'addressLine2', 'city']

  fields.forEach(field => {
    const key = `${tablePrefix}${field.toUpperCase()}`
    if (nodeDetails[field]) {
      record[key] = nodeDetails[field]
    }
  })

  return {
    record,
    tableName,
  }
}

const getNodeConfigData = (node, nestedLabel, addressBlockKeys) => {
  let options = []
  let addToDBData = null

  if (!nestedLabel) {
    const result = {
      options,
    }
    return result
  }

  options = options.concat(['rules', 'notInUse'])

  const enableLookup = node.qualifierParent !== 'references' && getNodeChildren(node).length === 0
  if (enableLookup) {
    options.push('lookup')
  }

  const enableAddToDB = addressBlockKeys.includes(nestedLabel)

  if (enableAddToDB) {
    options.push('addToDB')
    addToDBData = getAddToDBData(node, nestedLabel)
  }

  const result = {
    options,
  }

  if (addToDBData) {
    result.addToDBData = addToDBData
  }

  return result
}

const flatNodes = (nodes, expandedNodes, matchedNodes, selectedNode, highlightRootNodes, addressBlockKeys, depth = 0, parentNestedLabel = null, parentKeyId = null) => {
  let flatenNodes = []
  const allowedNodeTypes = ['root', 'document', 'key', 'key_detail', 'keyTextDetail', 'key_detail_robot', 'keyTextDetailRobot', 'key_detail_static', 'keyTextDetailStatic']

  const counts = {}
  const nodeIndexes = nodes.map(item => {
    const nodeType = item.type
    if (counts[nodeType] === undefined) {
      counts[nodeType] = 0
    } else {
      counts[nodeType] += 1
    }

    return counts[nodeType]
  })

  for (let index = 0; index < nodes.length; index += 1) {
    const node = nodes[index]

    if (!allowedNodeTypes.includes(node.type)) {
      // eslint-disable-next-line no-continue
      continue
    }

    if (node.type === 'key') {
      const searchResult = flatNodes(node.children, expandedNodes, matchedNodes, selectedNode, highlightRootNodes, addressBlockKeys, depth, parentNestedLabel, parentKeyId)
      flatenNodes = flatenNodes.concat(searchResult)
    } else {
      const expandable = !!(node.children && node.children.filter(childNode => allowedNodeTypes.includes(childNode.type)).length > 0)
      const expanded = expandable && expandedNodes.includes(node.id)
      const nodeIndex = nodeIndexes[index]

      const label = getNodeLabel(node)
      const title = getKeyNodeTitle(node, nodeIndex)

      const nodeStatus = node.STATUS || 0
      let badgeVariant = ''
      if (nodeStatus === 0) {
        badgeVariant = 'info'
      } else if (nodeStatus === -2) {
        badgeVariant = 'danger'
      } else if (nodeStatus === -1) {
        badgeVariant = 'warning'
      } else if (nodeStatus === 1) {
        badgeVariant = 'success'
      } else if (nodeStatus === 2) {
        badgeVariant = 'secondary'
      } else if (nodeStatus === 200) {
        badgeVariant = 'dark'
      } else if (nodeStatus === 111) {
        badgeVariant = 'primary'
      }

      const highlighted = !!(selectedNode && selectedNode.highlight && !highlightRootNodes && selectedNode.id === node.id)
      const searchMatch = matchedNodes.includes(node.id)

      let nestedLabel = null
      if (label) {
        if (parentNestedLabel) {
          nestedLabel = `${parentNestedLabel}.${label}`
        } else {
          nestedLabel = label
        }
      }
      const configData = getNodeConfigData(node, nestedLabel, addressBlockKeys)
      const keyId = node.unique_id || parentKeyId || null

      flatenNodes.push({
        id: node.id,
        title,
        expandable,
        expanded,
        test: node.test,
        depth,
        highlighted,
        searchMatch,
        type: node.type,
        label,
        badgeVariant,
        nestedLabel,
        keyId,
        configData,
      })

      if (expanded) {
        const searchResult = flatNodes(node.children, expandedNodes, matchedNodes, selectedNode, highlightRootNodes, addressBlockKeys, depth + 1, nestedLabel, keyId)
        flatenNodes = flatenNodes.concat(searchResult)
      }
    }
  }
  return flatenNodes
}

const getFlatNodes = (nodes, expandedNodes, matchedNodes, selectedNode, highlightRootNodes, addressBlockKeys) => flatNodes(nodes, expandedNodes, matchedNodes, selectedNode, highlightRootNodes, addressBlockKeys)

const parseNodes = (nodes, parentPageId = null, parentDocId = null) => {
  let flatenNodes = {}

  nodes.forEach(node => {
    let pageId
    if (node.type === 'page') {
      pageId = node.id
    } else {
      pageId = parentPageId || node.pageId
    }
    pageId = pageId || null

    let docId = parentDocId
    if (node.type === 'document' || node.type === 'docBuilder') {
      docId = node.id
    }

    const pos = node.pos || null
    const highlightForExcel = !!(node.cellRange && node.worksheet_name)
    const highlightForPdf = !!(pos && pageId && node.type !== 'page')
    const highlight = highlightForPdf || highlightForExcel

    flatenNodes[node.id] = {
      docId,
      pageId,
      pos,
      highlight,
      type: node.type,
      label: node.label,
      cellRange: node.cellRange,
      sheetName: node.worksheet_name,
    }

    if (node.children && node.children.length > 0) {
      const searchResult = parseNodes(node.children, pageId, docId)
      flatenNodes = { ...flatenNodes, ...searchResult }
    }
  })

  return flatenNodes
}

const getNodes = nodes => parseNodes(nodes)

const getWordNodes = nodes => {
  let wordNodes = []

  nodes.forEach(node => {
    if (node.type === 'word') {
      wordNodes.push(node)
    }

    if (node.children && node.children.length > 0) {
      const searchResult = getWordNodes(node.children)
      wordNodes = wordNodes.concat(searchResult)
    }
  })

  return wordNodes
}

const parsePages = nodes => {
  let flatenPages = {}
  nodes.forEach(node => {
    if (node.type === 'page') {
      const pageStyles = {}
      if (node.styles) {
        node.styles.forEach(pageStyle => {
          const styleObject = {}
          const styles = pageStyle.v.split(';')
          styles.forEach(style => {
            const [key, value] = style.split(':')
            if (key) {
              styleObject[key.trim()] = value.trim()
            }
          })
          pageStyles[pageStyle.id] = styleObject
        })
      }

      flatenPages[node.id] = {
        pos: node.pos || null,
        wordNodes: getWordNodes(node.children).map(wordNode => ({
          id: wordNode.id, pos: wordNode.pos, v: wordNode.v, styleId: wordNode.s,
        })),
        imageFile: node.IMAGEFILE,
        styles: pageStyles,
      }
    }

    if (node.children && node.children.length > 0) {
      const searchResult = parsePages(node.children)
      flatenPages = { ...flatenPages, ...searchResult }
    }
  })
  return flatenPages
}

const getPages = nodes => parsePages(nodes)

const parseDocumentRootNodes = pageNodes => {
  const res = {}
  pageNodes.forEach(pageNode => {
    res[pageNode.id] = pageNode.children.map(childNode => ({ pos: childNode.pos }))
  })
  return res
}

const parseDocBuilderDocumentRootNodes = lineNodes => {
  const res = {}
  lineNodes.forEach(lineNode => {
    if (res[lineNode.pageId] === undefined) {
      res[lineNode.pageId] = []
    }
    res[lineNode.pageId].push({ pos: lineNode.pos })
  })
  return res
}

const parseRootNodes = nodes => {
  let rootNodes = {}
  nodes.forEach(node => {
    if (node.type === 'document') {
      rootNodes[node.id] = parseDocumentRootNodes(node.children)
    } else if (node.type === 'docBuilder') {
      rootNodes[node.id] = parseDocBuilderDocumentRootNodes(node.children)
    }

    if (node.children && node.children.length > 0) {
      const result = parseRootNodes(node.children)
      rootNodes = { ...rootNodes, ...result }
    }
  })

  return rootNodes
}

const getRootNodeList = nodes => parseRootNodes(nodes)

const parseTable = tableNode => {
  const tableRows = tableNode.children.filter(node => node.type === 'row')
  const rows = tableRows.map(tablRowNode => {
    const row = {}
    const rowCells = tablRowNode.children.filter(node => node.type === 'cell')
    rowCells.forEach(rowCell => {
      if (rowCell.label) {
        row[rowCell.label] = {
          id: rowCell.id,
          v: rowCell.v,
          pageId: rowCell.pageId,
          pos: rowCell.pos,
          STATUS: rowCell.STATUS,
          cellRange: rowCell.cellRange,
          worksheet_name: rowCell.worksheet_name,
        }
      }
    })
    return row
  })
  return {
    rows,
  }
}

const parseTables = (nodes, documentId) => {
  let tables = []
  nodes.forEach(node => {
    if (node.type === 'table') {
      tables.push({
        id: node.id,
        table_unique_id: node.table_unique_id,
        documentId,
        ...parseTable(node),
      })
    } else if (node.children && node.children.length > 0) {
      let newDocumentId = documentId
      if (node.type === 'document') {
        newDocumentId = node.id
      }
      const result = parseTables(node.children, newDocumentId)
      tables = tables.concat(result)
    }
  })
  return tables
}

const getTables = nodes => parseTables(nodes, '')

const parseKeyBlocks = (nodes, parentDocumentId = null) => {
  let keyBlocks = []

  nodes.forEach(node => {
    let documentId = parentDocumentId
    if (node.type === 'document') {
      documentId = node.id
    }

    if (node.type === 'keyText') {
      keyBlocks.push({
        documentId,
        pageId: node.pageId,
        pos: node.pos,
      })
    }

    if (node.children && node.children.length > 0) {
      const searchResult = parseKeyBlocks(node.children, documentId)
      keyBlocks = keyBlocks.concat(searchResult)
    }
  })

  return keyBlocks
}

const getKeyBlocks = nodes => parseKeyBlocks(nodes)

const parseKeyNodes = (nodes, parentLabel = null) => {
  let keys = []

  nodes.forEach(node => {
    let label = getNodeLabel(node)
    label = parentLabel ? `${parentLabel}.${label}` : label
    const value = getKeyNodeTitle(node)

    keys.push({
      label,
      value,
      qualifierParent: node.qualifierParent || null,
    })

    if (node.children && node.children.length > 0) {
      const searchResult = parseKeyNodes(node.children, label)
      keys = keys.concat(searchResult)
    }
  })

  return keys
}

const parseKeys = (nodes, parentDocumentId = null) => {
  let keys = []

  nodes.forEach(node => {
    let documentId = parentDocumentId
    if (node.type === 'document') {
      documentId = node.id
    }

    if (node.type === 'key') {
      let parsedKeys = parseKeyNodes(node.children)
      parsedKeys = parsedKeys.map(keyData => ({
        ...keyData,
        documentId,
      }))
      keys = keys.concat(parsedKeys)
    } else if (node.children && node.children.length > 0) {
      const searchResult = parseKeys(node.children, documentId)
      keys = keys.concat(searchResult)
    }
  })

  return keys
}

const getKeys = nodes => parseKeys(nodes)

const parseDocuments = nodes => {
  let doucments = []
  nodes.forEach(node => {
    if (node.type === 'document') {
      doucments.push({
        id: node.id,
        language: node.Language,
      })
    }

    if (node.children && node.children.length > 0) {
      const searchResult = parseDocuments(node.children)
      doucments = doucments.concat(searchResult)
    }
  })
  return doucments
}

const getDocuments = nodes => {
  const documents = parseDocuments(nodes)
  const tables = getTables(nodes)
  const keyBlocks = getKeyBlocks(nodes)
  const keys = getKeys(nodes)

  const finalDocuments = {}
  documents.forEach(document => {
    const documentTables = tables.filter(table => table.documentId === document.id)
    const documentKeyBlocks = keyBlocks.filter(keyBlock => keyBlock.documentId === document.id)
    const documentKeys = keys.filter(key => key.documentId === document.id)

    const formatedTables = documentTables.map(table => {
      const newTable = { ...table }
      delete newTable.documentId
      return newTable
    })

    const formatedKeyBlocks = documentKeyBlocks.map(keyBlock => {
      const newKeyBlock = { ...keyBlock }
      delete newKeyBlock.documentId
      return newKeyBlock
    })

    const formatedKeys = documentKeys.map(key => {
      const newKey = { ...key }
      delete newKey.documentId
      return newKey
    })

    finalDocuments[document.id] = {
      language: document.language,
      tables: formatedTables,
      keyBlocks: formatedKeyBlocks,
      keys: formatedKeys,
    }
  })

  return finalDocuments
}

const getExcelData = nodes => {
  const documentNode = nodes[0]
  return {
    fileName: `${documentNode.sourceFileName}${documentNode.ext}`,
  }
}

const getBatchMediaURL = (batchId, subPath, fileName) => {
  let url = `${getEnv('VUE_APP_BACKEND_URL')}/batch-media`

  if (subPath && subPath !== '') {
    url += `/${subPath}`
  }

  const folderName = batchId
  url += `/${folderName}/${fileName}`
  return url
}
const getClassificationMediaURL = filePath => {
  const url = `${getEnv('VUE_APP_BACKEND_URL')}/batch-media/${filePath}`
  return url
}

const getKeyItemById = (keyItems, keyItemId) => {
  let allKeyItems = []
  keyItems.forEach(keyItem => {
    allKeyItems.push(keyItem)
    allKeyItems = allKeyItems.concat(keyItem.compoundItems)
  })
  return allKeyItems.find(keyItemToCheck => keyItemToCheck.id === keyItemId)
}

const idSplltier = cellId => {
  const splitedId = cellId.split('.')

  const batchId = `${splitedId[0]}.${splitedId[1]}`
  const documentId = `${batchId}.${splitedId[2]}`
  const keyId = `${documentId}.${splitedId[3]}`
  const keyItemId = `${keyId}.${splitedId[4]}`
  const keyItemChildId = splitedId.length === 6 ? `${keyItemId}.${splitedId[5]}` : null
  return {
    batchId, documentId, keyId, keyItemId, keyItemChildId,
  }
}

export {
  searchNodes, expandNodesByType, getNodes, getFlatNodes, getRootNodeList, getWordNodes, getDocuments, getPages, getExcelData, getBatchMediaURL, getClassificationMediaURL, getKeyItemById, idSplltier,
}
