import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import dataView from './data-view'
import applicationSettings from './application-settings'
import definitionSettings from './definition-settings'
import defaultSettings from './default-settings'
import batch from './batch'
import auth from './auth'
import theme from './theme'
import lookup from './lookup'
import atm from './atm'
import classifications from './classifications'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      key: 'vuex',
      paths: ['auth'],
    }),
  ],
  modules: {
    app,
    appConfig,
    applicationSettings,
    definitionSettings,
    defaultSettings,
    verticalMenu,
    dataView,
    batch,
    auth,
    theme,
    lookup,
    atm,
    classifications,
  },
  strict: process.env.DEV,
})
