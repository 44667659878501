import Vue from 'vue'
import { cloneDeep } from 'lodash'
import axios from 'axios'
import bus from '@/bus'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  searchNodes, expandNodesByType, getNodes, getFlatNodes, getDocuments, getPages, getExcelData, getKeyItemById, idSplltier,
} from './helper'

const defaultHighlightKeyAnchorsData = {
  keyItemId: null,
  selectedAnchor: null,
  source: null,
}

const defaultDocumentData = {
  pages: {},
  excelData: null,
}

export default {
  namespaced: true,
  state: {
    batch: null,
    batchNodes: [],
    manualValidation: false,
    verificationStatus: '',
    verificationDetails: [],
    nodes: [],
    rootNodeList: null,
    selectedNodeId: null,
    editableNode: null,
    highlightRootNodes: false,
    nodeConfig: {},
    search: '',
    expandedNodes: [],
    matchedNodes: [],
    zoom: 1,
    enableSelector: false,
    enableMeasure: false,
    view: 'key',
    mode: 'edit',
    status: null,
    refreshing: false,
    totalPages: 0,
    documents: {},
    selectedDocumentId: null,
    documentData: {
      ...defaultDocumentData,
    },
    loadingDocumentData: false,
    selectorPosition: null,
    measuredDistance: null,
    highlightKeyBlocks: false,
    chunkData: {},
    highlightKeyAnchorsData: {
      ...defaultHighlightKeyAnchorsData,
    },
    error: null,
    scrollToPos: null,
    testOptions: {
      skipPostProcessor: false,
      skipKeyProcessing: false,
      skipTableProcessing: false,
    },
    selectedCellDetails: null,
    positionShiftData: null,
    isAddRow: false,
    isDeleteRow: false,
    isAnyDeletableRow: true,
    currentEmailBatchId: '',
    tempImageList: [],
  },
  mutations: {
    SET_TEMP_IMGE_LIST(state, value) {
      state.tempImageList = value
    },
    SET_BATCH(state, batch) {
      state.batch = batch
    },
    SET_BATCH_NODES(state, batchNodes) {
      state.batchNodes = batchNodes
    },
    SET_VERIFICATION_STATUS(state, value) {
      state.verificationStatus = value
    },
    SET_MANUAL_VALIDATION(state, value) {
      state.manualValidation = value
    },
    SET_VERIFICATION_DETAILS(state, value) {
      state.verificationDetails = value
    },
    SET_SELECTED_NODE_ID(state, nodeId) {
      state.selectedNodeId = nodeId
    },
    SET_EDITABLE_NODE(state, node) {
      state.editableNode = node
    },
    SET_NODES(state, nodes) {
      state.nodes = nodes
    },
    SET_ROOT_NODE_LIST(state, value) {
      state.rootNodeList = value
    },
    SET_HIGHLIGHT_ROOT_NODES(state, status) {
      state.highlightRootNodes = status
    },
    SET_NODE_CONFIG(state, { id, value }) {
      Vue.set(state.nodeConfig, id, value)
    },
    CLEAR_NODE_CONFIG(state) {
      state.nodeConfig = {}
    },
    SET_SEARCH(state, value) {
      state.search = value
    },
    EXPAND_NODE(state, nodeId) {
      state.expandedNodes.push(nodeId)
    },
    COLLAPSE_NODE(state, nodeId) {
      state.expandedNodes = state.expandedNodes.filter(id => id !== nodeId)
    },
    SET_EXPNADED_NODES(state, expnadedNodeIds) {
      state.expandedNodes = expnadedNodeIds
    },
    SET_MATCHED_NODES(state, matchedNodeIds) {
      state.matchedNodes = matchedNodeIds
    },
    SET_ZOOM(state, zoom) {
      state.zoom = zoom
    },
    SET_ENABLE_SELECTOR(state, value) {
      state.enableSelector = value
    },
    SET_ENABLE_MEASURE(state, value) {
      state.enableMeasure = value
    },
    SET_VIEW(state, value) {
      state.view = value
    },
    SET_MODE(state, value) {
      state.mode = value
    },
    SET_STATUS(state, value) {
      state.status = value
    },
    SET_REFRESHING(state, value) {
      state.refreshing = value
    },
    SET_TOTAL_PAGES(state, value) {
      state.totalPages = value
    },
    SET_DOCUMENTS(state, documents) {
      state.documents = documents
    },
    SET_SELECTED_DOCUMENT_ID(state, value) {
      state.selectedDocumentId = value
    },
    SET_DOCUMENT_DATA(state, value) {
      state.documentData = value
    },
    SET_LOADING_DOCUMENT_DATA(state, value) {
      state.loadingDocumentData = value
    },
    SET_SELECTOR_POSITION(state, value) {
      state.selectorPosition = value
    },
    SET_MEASURED_DISTANCE(state, value) {
      state.measuredDistance = value
    },
    SET_HIGHLIGHT_KEY_BLOCKS(state, status) {
      state.highlightKeyBlocks = status
    },
    SET_CHUNK_DATA(state, value) {
      state.chunkData = value
    },
    SET_HIGHLIGHT_KEY_ANCHORS_DATA(state, { keyItemId, selectedAnchor, source }) {
      state.highlightKeyAnchorsData = {
        keyItemId,
        selectedAnchor,
        source,
      }
    },
    SET_ERROR(state, value) {
      state.error = value
    },
    SET_SCROLL_TO_POS(state, value) {
      state.scrollToPos = value
    },
    SET_TEST_OPTIONS(state, value) {
      state.testOptions = value
    },
    SET_SELECTED_CELL_DETAILS(state, value) {
      state.selectedCellDetails = value
    },
    SET_POSITION_SHIFT_DATA(state, value) {
      state.positionShiftData = value
    },
    TOGGLE_ADD_ROW(state, value) {
      state.isAddRow = value
    },
    TOGGLE_DELETE_ROW(state, value) {
      state.isDeleteRow = value
    },
    CHECK_DELETE_ROW(state, value) {
      state.isAnyDeletableRow = value
    },
  },
  actions: {
    setSelectedNodeId({ commit }, nodeId) {
      commit('SET_SELECTED_NODE_ID', nodeId)
    },
    setEditableNode({ state, commit }, node) {
      if (state.editableNode && node && state.editableNode.id === node.id) {
        state.editableNode.v = node.v

        return
      }

      commit('SET_EDITABLE_NODE', node)
    },
    setNodeConfig({ commit }, data) {
      commit('SET_NODE_CONFIG', data)
    },
    setSearch({ commit, state }, value) {
      let expandedIds = []
      let matchedIds = []
      if (value) {
        const searchResult = searchNodes(state.batchNodes, value)
        expandedIds = searchResult.expandedIds
        matchedIds = searchResult.matchedIds
      }

      commit('SET_SEARCH', value)
      commit('SET_EXPNADED_NODES', expandedIds)
      commit('SET_MATCHED_NODES', matchedIds)
      commit('SET_SELECTED_NODE_ID', null)
    },
    async checkBatchAvailability({ rootGetters }, batchId) {
      try {
        const res = await axios.get('/check_batch_availability', {
          params: {
            batch_id: batchId,
          },
        })

        const allDefinitions = rootGetters['dataView/allDefinitions']

        if (res.data.available && allDefinitions.includes(res.data.definition_id)) {
          return res.data.available
        }

        return false
      } catch {
        return false
      }
    },
    async fetchVerificationDetails({ commit, dispatch, state }, emailBatchId) {
      state.currentEmailBatchId = emailBatchId
      try {
        const res = await axios.get(`/pipeline/get_verification_details/${emailBatchId}/`)

        res.data.batches_info.sort((a, b) => {
          if (a.id < b.id) {
            return -1
          }

          if (a.id > b.id) {
            return 1
          }

          return 0
        })

        commit('SET_MANUAL_VALIDATION', res.data.manual_validation)
        commit('SET_VERIFICATION_STATUS', res.data.verification_status)
        commit('SET_VERIFICATION_DETAILS', res.data.batches_info)

        await dispatch('loadVerificationPage', { data: res.data.batches_info, emailBatchId })
      } catch (error) {
        const err = error?.response?.data?.detail || 'Error fetching verification details'

        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: err,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        commit('SET_ERROR', err)
        commit('dataView/SET_LOADING', false, { root: true })
      }
    },
    async loadVerificationPage({ commit, dispatch }, { data, emailBatchId }) {
      let batchNodeChildren = []

      data.forEach(e => {
        batchNodeChildren = [...batchNodeChildren, ...e.data_json.nodes]
      })

      const batchNodes = [{ type: 'root', id: emailBatchId, children: batchNodeChildren }]

      commit('SET_BATCH', { id: data[0].id, subPath: data[0].sub_path, isExcel: data[0].data_json.batch_type === '.xlsx' })
      commit('SET_BATCH_NODES', batchNodes)
      commit('SET_NODES', getNodes(batchNodes))

      const documents = getDocuments(batchNodes)

      commit('SET_DOCUMENTS', documents)

      await dispatch('expandNodes', ['document'])
      await dispatch('loadDocumentData')
      await dispatch('selectFirstDocument')

      await dispatch('dataView/setDJsonTableList', null, { root: true })
    },
    async fetchBatch({ dispatch }, selectedBatch) {
      try {
        const res = await axios.get(`/batches/${selectedBatch}/`)

        await dispatch('dataView/fetchSelectorDataByBatch', res.data, { root: true })
        await dispatch('loadBatch', res.data)
        await dispatch('expandNodes', ['document'])
        await dispatch('selectFirstDocument')
      } catch (error) {
        const err = error?.response?.data?.detail || 'Fetching batch'

        throw new Error(err)
      }
    },
    loadBatch({ commit }, batchData) {
      const data = batchData.data_json
      const batch = {
        id: batchData.id,
        vendor: batchData.vendor,
        type: batchData.type,
        nameMatchingText: batchData.name_matching_text,
        definitionId: batchData.definition_id,
        mode: batchData.mode,
        subPath: batchData.sub_path,
        definitionVersion: data.definition_version,
        isExcel: data.batch_type === '.xlsx',
        project: data.Project,
      }

      const batchNodes = [{ type: 'root', id: data.id, children: data.nodes }]

      commit('SET_BATCH', batch)
      commit('SET_BATCH_NODES', batchNodes)
      commit('SET_NODES', getNodes(batchNodes))
      commit('SET_DOCUMENTS', getDocuments(batchNodes))

      // commit('SET_ROOT_NODE_LIST', getRootNodeList(batchNodes))

      // local caching
      localStorage.setItem('previous_batch_id', batch.id)
    },
    async loadDocumentData({ commit, state }) {
      commit('SET_LOADING_DOCUMENT_DATA', true)
      try {
        const response = await axios.get('/ra_json/', {
          params: {
            batch_id: state.batch.id,
            document_id: state.selectedDocumentId,
          },
        })

        const documentData = { ...defaultDocumentData }
        if (state.batch.isExcel) {
          documentData.excelData = getExcelData(response.data.nodes)
        } else {
          documentData.pages = getPages(response.data.nodes)
        }

        commit('SET_TOTAL_PAGES', response.data.total_pages)
        commit('SET_DOCUMENT_DATA', documentData)
        commit('SET_LOADING_DOCUMENT_DATA', false)

        if (state.scrollToPos) {
          setTimeout(() => {
            bus.$emit('scrollToPos', state.scrollToPos)
            commit('SET_SCROLL_TO_POS', null)
          }, 1500)
        }
      } catch (error) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: error?.response?.data?.detail || 'Error fetching document data',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        commit('SET_DOCUMENT_DATA', { ...defaultDocumentData })
        commit('SET_LOADING_DOCUMENT_DATA', false)
      }
    },
    async fetchPositionShiftData({ rootGetters, commit }) {
      try {
        const selectedTableId = rootGetters['dataView/selectedTableId']
        const { table_unique_id } = rootGetters['dataView/table'][selectedTableId]

        const res = await axios.post('/pipeline/position_shift_data/', {
          batch_id: rootGetters['batch/batch'].id,
          table_unique_id,
          definition_version: rootGetters['dataView/selectedDefinitionVersion'],
        })

        commit('SET_POSITION_SHIFT_DATA', res?.data?.data || [])
      } catch (error) {
        const err = error?.response?.data?.detail || 'Fetching Position Shift data'

        throw new Error(err)
      }
    },
    selectFirstDocument({ commit, state }) {
      commit('SET_SELECTED_DOCUMENT_ID', Object.keys(state.documents)[0])
    },
    expandNodes({ commit, state }, nodeTypes) {
      const expandedIds = expandNodesByType(state.batchNodes, nodeTypes)
      commit('SET_EXPNADED_NODES', expandedIds)
    },
    zoomIn({ commit, state }) {
      const newVal = state.zoom + 0.1
      if (newVal <= 10) {
        commit('SET_ZOOM', newVal)
      }
    },
    zoomOut({ commit, state }) {
      const newVal = state.zoom - 0.1
      if (newVal > 0.1) {
        commit('SET_ZOOM', newVal)
      }
    },
    toggleSelector({ commit, state }) {
      commit('SET_ENABLE_SELECTOR', !state.enableSelector)
      if (state.enableSelector && state.enableMeasure) {
        commit('SET_ENABLE_MEASURE', false)
      }
    },
    toggleMeasure({ commit, state }) {
      commit('SET_ENABLE_MEASURE', !state.enableMeasure)
      if (state.enableMeasure && state.enableSelector) {
        commit('SET_ENABLE_SELECTOR', false)
      }
    },
    toggleHighlightRootNodes({ commit, state }) {
      commit('SET_HIGHLIGHT_ROOT_NODES', !state.highlightRootNodes)
    },
    toggleHighlightKeyBlocks({ commit, state }) {
      commit('SET_HIGHLIGHT_KEY_BLOCKS', !state.highlightKeyBlocks)
    },
    updateNodeValue({ commit, state }, data) {
      try {
        const {
          batchId, documentId, keyId, keyItemId, keyItemChildId,
        } = idSplltier(data.id)

        const verificationDetails = [...state.verificationDetails]
        const dataJsonIndex = verificationDetails.findIndex(e => e.id === batchId)

        const docIndex = verificationDetails[dataJsonIndex].data_json.nodes.findIndex(e => e.id === documentId)

        const document = verificationDetails[dataJsonIndex].data_json.nodes[docIndex]

        const docItemIndex = document.children.findIndex(e => e.id === keyId)

        const keyItemIndex = document.children[docItemIndex].children.findIndex(e => e.id === keyItemId)

        if (!keyItemChildId) {
          document.children[docItemIndex].children[keyItemIndex].v = data.nodeValue
        } else {
          const keyItemChildIdIndex = document.children[docItemIndex].children[keyItemIndex].children.findIndex(e => e.id === keyItemChildId)

          document.children[docItemIndex].children[keyItemIndex].children[keyItemChildIdIndex].v = data.nodeValue
        }

        verificationDetails[dataJsonIndex].data_json.nodes[docIndex] = document

        commit('SET_VERIFICATION_DETAILS', verificationDetails)
      } catch (error) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: error?.message || 'Error updating node value',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    addNodeWithValue({ commit, state }, data) {
      function getNextId(currentId) {
        const parts = currentId.split('.')
        let lastSegment = parseInt(parts[parts.length - 1], 10)
        lastSegment += 1
        parts[parts.length - 1] = lastSegment.toString().padStart(3, '0')
        return parts.join('.')
      }
      try {
        const {
          batchId, documentId, keyId, keyItemId, keyItemChildId,
        } = idSplltier(data.id)

        const verificationDetails = [...state.verificationDetails]

        const dataJsonIndex = verificationDetails.findIndex(e => e.id === batchId)

        const docIndex = verificationDetails[dataJsonIndex].data_json.nodes.findIndex(e => e.id === documentId)

        const document = verificationDetails[dataJsonIndex].data_json.nodes[docIndex]

        const docItemIndex = document.children.findIndex(e => e.id === keyId)

        const keyItemIndex = document.children[docItemIndex].children.findIndex(e => e.id === keyItemId)

        if (!keyItemChildId) {
          if (keyItemIndex > -1) {
            document.children[docItemIndex].children[keyItemIndex].v = data.v
            document.children[docItemIndex].children[keyItemIndex].pos = data.pos
          } else {
            const { children } = document.children[docItemIndex]
            const newCell = cloneDeep(children[children.length - 1])
            newCell.id = getNextId(newCell.id)
            newCell.v = data.v
            newCell.pos = data.pos
            newCell.label = data.label
            document.children[docItemIndex].children.push(newCell)
          }
        } else {
          const keyItemChildIdIndex = document.children[docItemIndex].children[keyItemIndex].children.findIndex(e => e.label === data.label)
          if (keyItemChildIdIndex > -1) {
            document.children[docItemIndex].children[keyItemIndex].children[keyItemChildIdIndex].v = data.v
            document.children[docItemIndex].children[keyItemIndex].children[keyItemChildIdIndex].pos = data.pos
          } else {
            const { children } = document.children[docItemIndex].children[keyItemIndex]
            const newCell = cloneDeep(children[children.length - 1])
            newCell.id = getNextId(newCell.id)
            newCell.v = data.v
            newCell.pos = data.pos
            newCell.label = data.label
            document.children[docItemIndex].children[keyItemIndex].children.push(newCell)
          }
        }
        commit('SET_VERIFICATION_DETAILS', verificationDetails)
      } catch (error) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: error?.message || 'Error updating node value',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async addRow({ commit, state, dispatch }, data) {
      const incrementId = id => {
        const parts = id.split('.')
        const lastPart = (parseInt(parts.pop(), 10) + 1).toString().padStart(3, '0')
        return [...parts, lastPart].join('.')
      }
      const incrementSecondLastPartOfId = id => {
        const parts = id.split('.')
        const secondLast = parts.length - 2
        parts[secondLast] = (parseInt(parts[secondLast], 10) + 1).toString().padStart(3, '0')
        return parts.join('.')
      }
      try {
        const {
          batchId, documentId, keyId, keyItemId, keyItemChildId,
        } = idSplltier(data.id)

        const verificationDetails = [...state.verificationDetails]

        const dataJsonIndex = verificationDetails.findIndex(e => e.id === batchId)

        const docIndex = verificationDetails[dataJsonIndex].data_json.nodes.findIndex(e => e.id === documentId)

        const document = verificationDetails[dataJsonIndex].data_json.nodes[docIndex]

        const docItemIndex = document.children.findIndex(e => e.id === keyId)

        if (!keyItemChildId) {
          // document.children[docItemIndex].children.push(data)
          document.children.forEach((item, index) => {
            if (index > docItemIndex) {
              // eslint-disable-next-line no-param-reassign
              item.id = incrementId(item.id)
              item.children.forEach(child => {
                // eslint-disable-next-line no-param-reassign
                child.id = incrementSecondLastPartOfId(child.id)
              })
            }
          })
          const copyObj = cloneDeep(document.children[docItemIndex])
          copyObj.id = incrementId(copyObj.id)
          copyObj.children = copyObj.children.map(child => ({
            ...child,
            id: incrementSecondLastPartOfId(child.id),
            pos: '',
            v: '',
          }))
          document.children.splice(docItemIndex + 1, 0, copyObj)
        } else {
          const keyItemIndex = document.children[docItemIndex].children.findIndex(e => e.id === keyItemId)
          document.children[docItemIndex].children.forEach((item, index) => {
            if (index > keyItemIndex) {
              // eslint-disable-next-line no-param-reassign
              item.id = incrementId(item.id)
              item.children.forEach(child => {
                // eslint-disable-next-line no-param-reassign
                child.id = incrementSecondLastPartOfId(child.id)
              })
            }
          })
          const copyObj = cloneDeep(document.children[docItemIndex].children[keyItemIndex])

          copyObj.id = incrementId(copyObj.id)

          copyObj.children = copyObj.children.map(child => ({
            ...child,
            id: incrementSecondLastPartOfId(child.id),
            pos: '',
            v: '',
          }))
          document.children[docItemIndex].children.splice(keyItemIndex + 1, 0, copyObj)
        }

        dispatch('setEditableNode', null)

        verificationDetails[dataJsonIndex].data_json.nodes[docIndex] = document
        commit('SET_VERIFICATION_DETAILS', verificationDetails)
      } catch (error) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: error?.message || 'Error updating node value',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async deleteRow({ commit, state, dispatch }, data) {
      try {
        const {
          batchId, documentId, keyId, keyItemId, keyItemChildId,
        } = idSplltier(data.id)

        const verificationDetails = [...state.verificationDetails]

        const dataJsonIndex = verificationDetails.findIndex(e => e.id === batchId)

        const docIndex = verificationDetails[dataJsonIndex].data_json.nodes.findIndex(e => e.id === documentId)

        const document = verificationDetails[dataJsonIndex].data_json.nodes[docIndex]

        const docItemIndex = document.children.findIndex(e => e.id === keyId)

        if (!keyItemChildId) {
          document.children.splice(docItemIndex, 1)
        } else {
          const keyItemIndex = document.children[docItemIndex].children.findIndex(e => e.id === keyItemId)
          document.children[docItemIndex].children.splice(keyItemIndex, 1)
        }

        dispatch('setEditableNode', null)

        verificationDetails[dataJsonIndex].data_json.nodes[docIndex] = document
        commit('SET_VERIFICATION_DETAILS', verificationDetails)
      } catch (error) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: error?.message || 'Error updating node value',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    setDocument({
      state, commit, getters, dispatch,
    }) {
      dispatch('setEditableNode', {})
      const verificationDetails = [...state.verificationDetails]
      const batchId = `${getters.selectedDocumentId.split('.')[0]}.${getters.selectedDocumentId.split('.')[1]}`
      const dataJsonIndex = verificationDetails.findIndex(e => e.id === batchId)
      const batchNodeChildren = verificationDetails[dataJsonIndex].data_json.nodes

      const batchNodes = [{ type: 'root', id: state.currentEmailBatchId, children: batchNodeChildren }]
      const formatedDocuments = getDocuments(batchNodes)
      commit('SET_DOCUMENTS', formatedDocuments)
      dispatch('setEditableNode', null)
    },
    async scrollToPos({
      dispatch, commit, state,
    }, pos) {
      // eslint-disable-next-line no-unused-vars
      const [left, top, right, bottom, pageId, documentId, batchId, posRef, _, refStatus] = pos

      const scrollToPos = {
        pos: [left, top, right, bottom].join(','),
        pageId,
      }

      let timeout = 0

      if (state.batch.id !== batchId) {
        await dispatch('dataView/onChangeBatch', { batchId, refresh: false }, { root: true })

        timeout = 300
      }

      if (documentId !== state.selectedDocumentId) {
        commit('SET_SCROLL_TO_POS', scrollToPos)
        commit('SET_SELECTED_DOCUMENT_ID', documentId)
      } else {
        setTimeout(() => {
          bus.$emit('scrollToPos', scrollToPos)
        }, timeout)
      }
    },
    reset({ commit }) {
      commit('SET_BATCH', null)
      commit('SET_BATCH_NODES', [])
      commit('SET_NODES', [])
      commit('SET_ROOT_NODE_LIST', null)
      commit('SET_SELECTED_NODE_ID', null)
      commit('SET_EDITABLE_NODE', null)
      commit('CLEAR_NODE_CONFIG')
      commit('SET_SEARCH', '')
      commit('SET_EXPNADED_NODES', [])
      commit('SET_MATCHED_NODES', [])
      commit('SET_HIGHLIGHT_ROOT_NODES', false)
      commit('SET_ZOOM', 1)
      commit('SET_ENABLE_SELECTOR', false)
      commit('SET_VIEW', 'key')
      commit('SET_MODE', 'edit')
      commit('SET_STATUS', null)
      commit('SET_REFRESHING', false)
      commit('SET_TOTAL_PAGES', 0)
      commit('SET_DOCUMENTS', {})
      commit('SET_SELECTED_DOCUMENT_ID', null)
      commit('SET_DOCUMENT_DATA', { ...defaultDocumentData })
      commit('SET_LOADING_DOCUMENT_DATA', false)
      commit('SET_SELECTOR_POSITION', null)
      commit('SET_HIGHLIGHT_KEY_BLOCKS', false)
      commit('SET_CHUNK_DATA', {})
      commit('SET_ENABLE_MEASURE', false)
      commit('SET_MEASURED_DISTANCE', null)
      commit('SET_HIGHLIGHT_KEY_ANCHORS_DATA', defaultHighlightKeyAnchorsData)
      commit('SET_SELECTED_CELL_DETAILS', null)
    },
    refreshBatchData({ commit, dispatch, state }) {
      commit('SET_REFRESHING', true)
      axios.get(`/batches/${state.batch.id}/`)
        .then(res => {
          dispatch('loadBatch', res.data)
          dispatch('atm/loadAtmPatterns', res.data?.atm_data, { root: true })
          commit('SET_REFRESHING', false)
        })
        .catch(error => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: error?.response?.data?.detail || 'Error refreshing batch',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          commit('SET_REFRESHING', false)
        })
    },
    loadChunkData({ commit, state, rootGetters }) {
      return new Promise((resolve, reject) => {
        const { batch } = state

        const selectedTableId = rootGetters['dataView/selectedTableId']
        const { table_unique_id } = rootGetters['dataView/table'][selectedTableId]

        axios.post('/pipeline/chunk_data/', {
          batch_id: batch.id,
          table_unique_id,
          definition_version: rootGetters['dataView/selectedDefinitionVersion'],
        })
          .then(res => {
            const chunkDataResponse = res.data.data
            const chunkData = {}

            Object.keys(chunkDataResponse).forEach(documentId => {
              chunkData[documentId] = {
                chunkLines: [],
              }
              const chunksData = chunkDataResponse[documentId].chunking_data
              const chunkShapesData = chunkDataResponse[documentId].chunking_shape_data
              Object.keys(chunksData).forEach(pageIndex => {
                Object.keys(chunksData[pageIndex]).forEach(line => {
                  const chunks = []
                  chunksData[pageIndex][line].forEach((chunkItem, index) => {
                    chunks.push({
                      value: chunkItem[0],
                      pos: chunkItem[1],
                      pageId: chunkItem[2],
                      shape: chunkShapesData[pageIndex][line][index][0],
                    })
                  })
                  chunkData[documentId].chunkLines.push({
                    chunks,
                  })
                })
              })
            })

            commit('SET_CHUNK_DATA', chunkData)
            resolve()
          })
          .catch(error => {
            const message = error?.response?.data?.detail || 'Error loading chunk data'
            commit('SET_CHUNK_DATA', {})
            reject(new Error(message))
          })
      })
    },
    clearAnchorHighlights({ commit }) {
      commit('SET_HIGHLIGHT_KEY_ANCHORS_DATA', defaultHighlightKeyAnchorsData)
    },
  },
  getters: {
    batch(state) {
      return state.batch
    },
    batchNodes(state) {
      return state.batchNodes
    },
    verificationStatus(state) {
      return state.verificationStatus
    },
    manualValidation(state) {
      return state.manualValidation
    },
    verificationDetails(state) {
      return state.verificationDetails
    },
    matchedNodes(state) {
      return state.matchedNodes
    },
    nodeConfig(state) {
      return state.nodeConfig
    },
    editableNode(state) {
      return state.editableNode
    },
    selectedNode(state) {
      if (state.selectedNodeId) {
        return {
          id: state.selectedNodeId,
          ...state.nodes[state.selectedNodeId],
        }
      }
      return null
    },
    selectedPageId(state, getters) {
      return getters.selectedNode ? getters.selectedNode.pageId : null
    },
    highlightRootNodes(state) {
      return state.highlightRootNodes
    },
    search(state) {
      return state.search
    },
    isExpandedNode: state => id => state.expandedNodes.includes(id),
    isMatchedNode: state => id => state.matchedNodes.includes(id),
    zoom(state) {
      return state.zoom
    },
    enableSelector(state) {
      return state.enableSelector
    },
    enableMeasure(state) {
      return state.enableMeasure
    },
    rootNodeList(state) {
      return state.rootNodeList
    },
    expandedNodes: state => state.expandedNodes,
    flatNodes(state, getters, rootGetters) {
      const addressBlockKeys = []
      const { options } = rootGetters.definitionSettings

      if (Object.keys(options).length) {
        options['options-keys'].items.forEach(e => {
          if (e.type === 'addressBlock') {
            addressBlockKeys.push(e.keyValue)
          }
        })
      }

      return getFlatNodes(state.batchNodes, state.expandedNodes, state.matchedNodes, getters.selectedNode,
        state.highlightRootNodes, addressBlockKeys)
    },
    view(state) {
      return state.view
    },
    mode(state) {
      return state.mode
    },
    status(state) {
      return state.status
    },
    refreshing(state) {
      return state.refreshing
    },
    documents(state) {
      return state.documents
    },
    totalPages(state) {
      return state.totalPages
    },
    selectedDocumentId(state) {
      return state.selectedDocumentId
    },
    selectedDocument(state, getters) {
      if (getters.selectedDocumentId) {
        return {
          id: getters.selectedDocumentId,
          ...state.documents[getters.selectedDocumentId],
        }
      }
      return null
    },
    selectedDocumentIndex(state) {
      if (!state.selectedDocumentId) {
        return -1
      }

      return Object.keys(state.documents).findIndex(documentId => documentId === state.selectedDocumentId)
    },
    selectedDocumentKeysForLookup(state, getters) {
      if (!state.selectedDocumentId) {
        return []
      }

      const { keys } = state.documents[getters.selectedDocumentId]

      return keys.filter(key => key.qualifierParent !== 'references').map(key => ({
        label: key.label,
        value: key.value,
      }))
    },
    selectedPage(state, getters) {
      if (getters.selectedDocument && getters.selectedPageId) {
        const page = state.documentData.pages[getters.selectedPageId]
        if (!page) {
          return null
        }
        return {
          id: getters.selectedPageId,
          ...page,
        }
      }
      return null
    },
    documentData(state) {
      return state.documentData
    },
    loadingDocumentData(state) {
      return state.loadingDocumentData
    },
    selectorPosition(state) {
      return state.selectorPosition
    },
    measuredDistance(state) {
      return state.measuredDistance
    },
    highlightKeyBlocks(state) {
      if (state.view !== 'analyzer') {
        return false
      }

      return state.highlightKeyBlocks
    },
    chunkData(state) {
      if (!state.selectedDocumentId) {
        return null
      }
      return state.chunkData[state.selectedDocumentId]
    },
    highlightKeyAnchorsData(state) {
      return state.highlightKeyAnchorsData
    },
    displayKeyAnchors(state, getters, rootState, rootGetters) {
      const { keyItemId, source } = state.highlightKeyAnchorsData
      if (keyItemId === null) {
        return false
      }
      const keyItems = rootGetters[`dataView/${source}`]
      const keyItem = getKeyItemById(keyItems, keyItemId)
      if (!keyItem) {
        return false
      }

      if (keyItem.type === 'anchors') {
        if (!keyItem.anchorShapes || keyItem.anchorShapes == null) {
          return false
        }
      }

      if (keyItem.type === 'regexExtractor') {
        if (!keyItem.regexExtractor || keyItem.regexExtractor == null) {
          return false
        }
      }

      return true
    },
    keyAnchorsData(state, getters, rootState, rootGetters) {
      if (!getters.displayKeyAnchors) {
        return null
      }
      const { keyItemId, selectedAnchor, source } = state.highlightKeyAnchorsData
      const keyItems = rootGetters[`dataView/${source}`]
      const keyItem = getKeyItemById(keyItems, keyItemId)

      let anchorShapes
      if (keyItem.type === 'anchors') {
        anchorShapes = keyItem.anchorShapes
      } else if (keyItem.type === 'regexExtractor') {
        anchorShapes = keyItem.regexExtractor.anchors
      }

      return {
        ...anchorShapes,
        selectedAnchor,
      }
    },
    error(state) {
      return state.error
    },
    testOptions(state) {
      return state.testOptions
    },
    selectedCellDetails(state) {
      return state.selectedCellDetails
    },
    positionShiftData(state) {
      return state.positionShiftData
    },
    getIsAddRow(state) {
      return state.isAddRow
    },
    getIsDeleteRow(state) {
      return state.isDeleteRow
    },
    getIsAnyDeletableRow(state) {
      return state.isAnyDeletableRow
    },
    getTempImageList(state) {
      return state.tempImageList
    },
  },
}
