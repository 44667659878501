import axios from 'axios'

import bus from '@/bus'
import getEnv from '@/utils/env'

const joinedRooms = {}
let connection = null
const pendingOperations = []

const isConnectionHealthy = () => {
  if (connection && connection.readyState === 1) {
    return true
  }
  return false
}

const messageHandler = event => {
  const eventData = JSON.parse(event.data)
  if (eventData.type === 'batch_status') {
    bus.$emit('wsData/batchStatus', eventData.data)
  } else if (eventData.type === 'batch_status_tag') {
    bus.$emit('wsData/batchStatusTag', eventData.data)
  } else if (eventData.type === 'email_batch_status_tag') {
    bus.$emit('wsData/emailBatchStatusTag', eventData.data)
  } else if (eventData.type === 'template_batch_status_tag') {
    bus.$emit('wsData/templateBatchStatusTag', eventData.data)
  } else if (eventData.type === 'timeline') {
    bus.$emit('wsData/timelineStatus', eventData.data)
  } else if (eventData.type === 'train_batch_status_tag') {
    bus.$emit('wsData/trainBatchStatusTag', eventData.data)
  }
}

const joinRoom = roomName => {
  if (!isConnectionHealthy()) {
    pendingOperations.push({
      action: 'joinRoom',
      roomName,
    })
    return
  }

  if (joinedRooms[roomName] === undefined) {
    joinedRooms[roomName] = 0
    connection.send(JSON.stringify({ type: 'join_room', room_name: roomName }))
  }
  joinedRooms[roomName] += 1
}

const leaveRoom = roomName => {
  if (!isConnectionHealthy()) {
    pendingOperations.push({
      action: 'leaveRoom',
      roomName,
    })
    return
  }

  joinedRooms[roomName] -= 1
  if (joinedRooms[roomName] === 0) {
    connection.send(JSON.stringify({ type: 'leave_room', room_name: roomName }))
    delete joinedRooms[roomName]
  }
}

const resolvePendingOperations = () => {
  pendingOperations.forEach(pendingOperation => {
    if (pendingOperation.action === 'joinRoom') {
      joinRoom(pendingOperation.roomName)
    } else if (pendingOperation.action === 'leaveRoom') {
      leaveRoom(pendingOperation.roomName)
    }
  })
}

const createConnection = () => {
  axios.get('/access_control/ws_ticket/')
    .then(res => {
      const wsTicket = res.data.ws_ticket

      connection = new WebSocket(`${getEnv('VUE_APP_WEBSOCKET_URL')}?ws_ticket=${wsTicket}`)

      connection.onopen = () => {
        resolvePendingOperations()
      }

      connection.onmessage = event => {
        messageHandler(event)
      }

      connection.onclose = event => {
        if (!event.wasClean) {
          bus.$emit('wsError', 'Websocket connection failed')
        }
      }
    })
    .catch(() => {
      bus.$emit('wsError', 'Error fetching websocket ticket')
    })
}

const closeConnection = () => {
  if (connection) {
    connection.close()
  }
}

const WS = {
  createConnection,
  closeConnection,
  joinRoom,
  leaveRoom,
}

export default WS
