<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component
      :is="layout"
      v-if="!loading"
    >
      <router-view />
    </component>
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import axios from 'axios'

import WS from '@/utils/ws'
import { getItemWithExpiration } from '@/utils/core'
import bus from '@/bus'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    // Layouts
    LayoutHorizontal,
    LayoutFull,
  },
  data() {
    return {
      loading: true,
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    authToken() {
      return this.$store.getters['auth/token']
    },
    userName() {
      return this.$store.getters['auth/userName']
    },
    appTitle() {
      return this.$store.getters['theme/settings'].appTitle
    },
    pageTitle() {
      return this.$store.getters['app/currentPageTitle']
    },
    documentTitle() {
      const { pageTitle, appTitle } = this
      const title = pageTitle ? `${pageTitle} - ${appTitle}` : appTitle
      return title
    },
    faviconUrl() {
      return this.$store.getters['theme/settings'].favicon
    },
  },
  watch: {
    authToken() {
      this.refreshAxiosToken()
      this.refreshWebSocketConnection()
    },
    documentTitle() {
      this.updateDocumentTitle()
    },
    faviconUrl() {
      this.updateFavicon()
    },
    $route(to/* , from */) {
      this.$store.commit('app/SET_CURRENT_ROUTE_NAME', to.name)
    },

  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
  created() {
    this.initApp()
    bus.$on('wsError', this.onWsError)
    this.$store.commit('app/SET_CURRENT_ROUTE_NAME', this.$route.name)
  },
  destroyed() {
    bus.$off('wsError', this.onWsError)
  },
  methods: {
    async initApp() {
      this.loading = true

      try {
        await this.$store.dispatch('theme/fetchThemeSettings')
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }

      this.updateDocumentTitle()

      this.refreshAxiosToken()
      this.refreshWebSocketConnection()

      if (this.authToken) {
        await this.refreshAuthData()
      }

      axios.interceptors.response.use(undefined, error => {
        if (error.response && error.response.status === 401) {
          this.$store.dispatch('auth/clearAuthData')
          this.$router.push({ name: 'login', query: { redirect: this.$route.fullPath } })
        }
        return Promise.reject(error)
      })

      this.loading = false
      this.$nextTick(() => {
        const appLoading = document.getElementById('loading-bg')
        if (appLoading) {
          appLoading.style.display = 'none'
        }
      })
    },
    refreshAxiosToken() {
      if (this.authToken) {
        axios.defaults.headers.common.Authorization = `Bearer ${this.authToken}`
      } else {
        delete axios.defaults.headers.common.Authorization
      }
    },
    refreshWebSocketConnection() {
      if (this.authToken) {
        WS.createConnection()
      } else {
        WS.closeConnection()
      }
    },
    async refreshAuthData() {
      try {
        const username = getItemWithExpiration('auth-username')

        if (username && sessionStorage.getItem('auth-username')) {
          return
        }

        const params = {
          username: username || this.userName,
        }

        const res = await axios.get('/access_control/get_user_data/', { params })

        const { data } = res

        data.token = this.authToken
        data.keepSelectedItems = true

        await this.$store.dispatch('auth/setAuthData', data)

        // Update last_login to track inactivity
        await axios.post('/access_control/update_last_login/', null, { params })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    onWsError(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'AlertTriangleIcon',
          variant: 'danger',
          text: 'Refresh the page to re-establish websocket connection.',
        },
      }, {
        timeout: false,
      })
    },
    updateDocumentTitle() {
      document.title = this.documentTitle
    },
    updateFavicon() {
      const favicon = document.getElementById('favicon')
      favicon.href = this.faviconUrl
    },
  },
}
</script>
