import { setItemWithExpiration } from '@/utils/core'

export default {
  namespaced: true,
  state: {
    token: null,
    userName: null,
    isAdmin: false,
    projectCountries: [],
    selectedProjectCountries: [],
  },
  mutations: {
    SET_AUTH_DATA(state, authData) {
      state.token = authData.token
      state.userName = authData.userName
      state.isAdmin = authData.isAdmin
      state.projectCountries = authData.projectCountries
    },
    SET_SELECTED_PROJECT_COUNTRIES(state, data) {
      state.selectedProjectCountries = data
    },
  },
  actions: {
    setAuthData({ commit, dispatch, getters }, data) {
      const projectCountries = []

      data.user.project_countries.forEach(item => {
        item.countries.forEach(country => {
          projectCountries.push({
            countryCode: country.code,
            country: country.name,
            project: item.project.name,
          })
        })
      })

      projectCountries.sort((a, b) => a.country.localeCompare(b.country))

      const authData = {
        token: data.token,
        userName: data.user.username,
        isAdmin: data.user.is_superuser,
        projectCountries,
      }

      // Local caching and session control
      setItemWithExpiration('auth-username', authData.userName, 24 * 60 * 60 * 1000)
      sessionStorage.setItem('auth-username', authData.userName)

      // Set selected project countries
      if (data.keepSelectedItems) {
        const { selectedProjectCountries } = getters

        dispatch('setSelectedProjectCountries', selectedProjectCountries)
      } else {
        dispatch('setSelectedProjectCountries', projectCountries)
      }

      commit('SET_AUTH_DATA', authData)
    },
    setSelectedProjectCountries({ commit }, data) {
      commit('SET_SELECTED_PROJECT_COUNTRIES', data)
    },
    clearAuthData({ commit }) {
      commit('SET_AUTH_DATA', {
        token: null,
        userName: null,
        isAdmin: false,
        projectCountries: [],
      })
    },
  },
  getters: {
    isAuthenticated(state) {
      return !!state.token
    },
    userName(state) {
      return state.userName
    },
    token(state) {
      return state.token
    },
    isAdmin(state) {
      return state.isAdmin
    },
    projectCountries(state) {
      return state.projectCountries
    },
    selectedProjectCountries(state) {
      return state.selectedProjectCountries
    },
  },
}
