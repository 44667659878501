import Vue from 'vue'
// axios
import axios from 'axios'

import getEnv from '@/utils/env'

const apiBaseUrl = `${getEnv('VUE_APP_BACKEND_URL')}/api`

axios.defaults.baseURL = apiBaseUrl

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: apiBaseUrl,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
