import axios from 'axios'
import { cloneDeep } from 'lodash'

import getEnv from '@/utils/env'

const defaultSettings = {
  appTitle: 'Data Definitions Editor',
  light: {
    logo: null,
    headerColor: null,
    appTitleColor: null,
  },
  dark: {
    logo: null,
    headerColor: null,
    appTitleColor: null,
  },
}

export default {
  namespaced: true,
  state: {
    settings: defaultSettings,
  },
  mutations: {
    SET_THEME_SETTINGS(state, data) {
      state.settings = data
    },
  },
  actions: {
    fetchThemeSettings({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/theme/theme_settings/')
          .then(res => {
            const resData = res.data

            const settings = cloneDeep(defaultSettings)

            if (resData?.logo_light_mode) {
              settings.light.logo = `${getEnv('VUE_APP_BACKEND_URL')}${resData.logo_light_mode}`
            }

            if (resData?.logo_dark_mode) {
              settings.dark.logo = `${getEnv('VUE_APP_BACKEND_URL')}${resData.logo_dark_mode}`
            }

            if (resData?.header_color_light_mode) {
              settings.light.headerColor = resData.header_color_light_mode
            }

            if (resData?.header_color_dark_mode) {
              settings.dark.headerColor = resData.header_color_dark_mode
            }

            if (resData?.favicon) {
              settings.favicon = `${getEnv('VUE_APP_BACKEND_URL')}${resData.favicon}`
            }

            if (resData?.app_title) {
              settings.appTitle = resData.app_title
            }

            if (resData?.app_title_color_light_mode) {
              settings.light.appTitleColor = resData.app_title_color_light_mode
            }

            if (resData?.app_title_color_dark_mode) {
              settings.dark.appTitleColor = resData.app_title_color_dark_mode
            }

            commit('SET_THEME_SETTINGS', settings)
            resolve()
          })
          .catch(error => {
            const message = error?.response?.data?.detail || 'Error fetching theme settings'
            reject(new Error(message))
          })
      })
    },
  },
  getters: {
    settings(state) {
      return state.settings
    },
  },
}
