import { extend } from 'vee-validate'

extend('selectTextFromImage', {
  message: 'Please select text from image',
  params: ['selectionStatus'],
  validate: (value, { selectionStatus }) => {
    if (!value) {
      return true
    }

    if (selectionStatus === 'true') {
      return true
    }

    return false
  },
})

extend('validAnchorShape', {
  message: 'Please select valid anchor shape',
  validate: value => {
    if (value.text && !value.pos) {
      return false
    }
    return true
  },
})

extend('requireAtleastOneAnchorShape', {
  message: 'Please select atleast one anchor shape',
  params: ['propertyName'],
  validate: (value, { propertyName }) => {
    let isValid = false

    let anchors
    if (propertyName === 'root') {
      anchors = value
    } else {
      anchors = value[propertyName]
    }

    const anchorPositions = ['top', 'bottom', 'left', 'right']
    for (let index = 0; index < anchorPositions.length; index += 1) {
      const anchorPosition = anchorPositions[index]
      if (anchors[anchorPosition]?.pos) {
        isValid = true
        break
      }
    }

    return isValid
  },
})

extend('required-allow-space', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1,
    }
  },
  message: 'The {_field_} field is required',
  computesRequired: true,
})
