export default {
  namespaced: true,
  state: {
    defaultBehaviour: {
      compoundKeys: [
        'housebill',
        'masterbill',
      ],
    },
  },
  mutations: {},
  actions: {},
  getters: {
    defaultBehaviour(state) {
      return state.defaultBehaviour
    },
  },
}
