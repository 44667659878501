/* eslint-disable import/prefer-default-export */
import { v4 as uuidv4 } from 'uuid'

const getNewTable = (tableSettings, tableId = 0, tableName = 'Main Table') => {
  const newTable = {
    table_id: tableId,
    table_unique_id: uuidv4(),
    table_name: tableName,
    table_definition_data: {
      models: {},
      columns: [],
      keyItems: [],
      ruleItems: [],
      normalizerItems: [],
    },
  }

  const models = {}
  const modelFields = tableSettings.model.fields

  modelFields.forEach(modelField => {
    let value

    if (modelField.defaultValue !== undefined) {
      value = modelField.defaultValue
    } else {
      value = ''
    }

    models[modelField.key] = value
  })

  newTable.table_definition_data.models = models

  return newTable
}

const getDefinitionKey = data => {
  const keyData = data?.key

  return ({
    models: keyData?.models || [],
    items: keyData?.items || [],
    ruleItems: keyData?.ruleItems || [],
    sampleBlocks: keyData?.sampleBlocks || [],
    notInUseItems: keyData?.notInUseItems || [],
    lookupItems: keyData?.lookupItems || [],
  })
}

export {
  getNewTable,
  getDefinitionKey,
}
