import axios from 'axios'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
/* eslint-disable indent, quote-props */
export default {
  namespaced: true,
  state: {
    classificationData: {},
    classificationRaJosn: {},
    verified: false,
    test: false,
    newCreatedTrainID: '',
  },
  getters: {
    getClassificationData(state) {
      return state.classificationData
    },
    getClassificationRaJosn(state) {
      return state.classificationRaJosn
    },
    getVerified(state) {
      return state.verified
    },
    getAlldocs(state) {
      const docs = []
      if (state.classificationRaJosn) {
        state.classificationRaJosn.nodes.forEach(element => {
          element.children.forEach(el => {
            docs.push(el)
          })
         })
      }
      return docs
    },
    getNewCreatedTrainID(state) {
      return state.newCreatedTrainID
    },
  },
  mutations: {
    SET_CLASSIFICATION_DATA(state, value) {
      state.classificationData = value
    },
    SET_CLASSIFICATION_RAJSON(state, value) {
      state.classificationRaJosn = value
    },
    SET_Test(state, value) {
      state.test = value
    },
    SET_VERIFIED(state, value) {
      state.verified = value
    },
    SET_NEW_CREATED_TRAIN_ID(state, value) {
      state.newCreatedTrainID = value
    },
  },
  actions: {
    async fetchRaJsonClassification({ commit }, batchId) {
      try {
        const res = await axios.get('/get_train_batch_ra_json/', {
          params: {
            batch_id: batchId,
          },
        })
        commit('SET_CLASSIFICATION_RAJSON', res.data)

        return false
      } catch {
        return false
      }
    },
    async fetchManualClassification({ commit }, batchId) {
      try {
        const res = await axios.get('/pipeline/get_manual_classification_data/', {
          params: {
            train_batch_id: batchId,
          },
        })
        commit('SET_VERIFIED', false)
        commit('SET_CLASSIFICATION_DATA', res.data)

        return false
      } catch {
        return false
      }
    },
    async testClassification({ state, commit }, batchId) {
      try {
        const res = await axios.post('/pipeline/test_manual_classification/', {
          train_batch_id: batchId,
          profile: state.classificationData.profile,
          manual_classification_data: state.classificationData.data,
        })
        commit('SET_VERIFIED', true)
        commit('SET_CLASSIFICATION_DATA', {
          ...state.classificationData,
          data: res.data,
        })
        commit('SET_Test', res)
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Classification testing successful',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        return false
      } catch (err) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: err,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        return false
      }
    },
    async verifyClassification({ state, commit }, batchId) {
      try {
        const res = await axios.post('/pipeline/verify_manual_classification/', {
          train_batch_id: batchId,
          profile: state.classificationData.profile,
          manual_classification_data: state.classificationData.data,
        })
        commit('SET_Test', res)
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: res.data.detail,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        return true
      } catch (err) {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: err,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
      return false
    },
  },
}
